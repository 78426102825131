
import cookie from 'react-cookies';
import { createStore } from "redux";
import reducer, { StoreMasterData, StoreUserData, StoreUserInfoData } from "./DataStore";
import worker_script from "./worker.js";
import { TableLastSyncDateTime, PSdateFormate,clearUserRelatedData,ProcessUserData,PrepAggregates,GetLocalStorageDetailsFor, FlushServiceWorkerCache, GetClientType, getCurrentDateTime, GetDateDiff, getUserToken, groupBy, HandleOffline, SaveExceptionToSentry, showLockedBox, ShowToastMessage,SaveCleintWaufli, PrepIndividualCenterAggregates } from "./RUtility";
import $ from 'jquery';

let apiCacheName = 'APICACHE';


var webSocket;
var globalSyncCallBack;
var globalMirracleSyncCallBack;
var globalActivitiesCallBack;
var globalMConfigCallBack;
var globalMReportCallBack;
var globalMReportDataCallBack;
var globalDataCreationCallBack;
var globalCustomRoyaltyCallBack;
var globalUserCustomDataCallBack;
var globalCustomLeadCallBack;
var globalDataBatchUpdateCallBack;
var globalDataDeletionCallBack;
var globalAggreegatesCallBack;
var globalUsersDaataCallBack;
var globalLeadsDataCallBack;
var globalProfileCardCallBack;
var globalUserInfoCallBack;
var globalLeadHistoryCallBack;
var globalGoalsCompletedCallBack;
var globalUserCreationCallBack;
var globalGroupsDataCallBack;
var globalSendOTPCallBack;
var globalValidateOTPCallBack;
var globalRemoveFamilyCallBack;
var globalNatureCallBack;
var globalKuberaCallBack;
var globalKuberaPaymentsCallBack;
var globalQueryBasedDataCallBack;
var globalResponseUnlockAccountCallBack;
var globalResponseMirraclesLoadMore;
var alreadyLoadMoreCallIsGoing = false;
var globalMirraclesDataCallBack;
var globalDataByIdCallBack;
var globalResponseMirraclesSearch;
var globalCourseDataCallBack;
var globalGiftVoucherCallBack;
var globalMirracleByTypeDataCallBack;
var globalMiraVerseLandingDataCallBack;
var globalSendWhatsappCallBack;
var globalPayslipCallBack;
var globalSalaryCallBack;
var globalCLeavesCallBack;
var globalAvailableslotsCallBack;
var globalGraceCompos;
var globalGetPinnacleTvDevices
var globalOtpAnalytics;
var globalBiometricData;
var connectCallback;
var globalPinnacleAssistant;

var isSyncing = false;
var isMirracleSyncing = false;
var appdataStore;
let failedCounter = 0;
let startDateOfSyncHandling;

function callBackForSocketReadyState(callBack) {
    if (webSocket.readyState == 1) {
        failedCounter = 0;
        callBack(true);
    }
    else {
        setTimeout(function () {
            callBackForSocketReadyState(callBack);
        }, 50);
    }
}

async function websocket(url, callBack) {
    try {
        console.log("webb socket");
        console.log(url);
        console.log(callBack);
        webSocket = new WebSocket(url);

        if (!webSocket) {
            throw new Error("server didn't accept ws")
        }

        webSocket.addEventListener("open", () => {
            console.log('Opened websocket');
            //setTimeout(function () {
            // webSocket.readyState = 1;

            callBackForSocketReadyState(callBack);
            //}
            //}, 100);
        });

        webSocket.addEventListener("message", ({ data }) => {
            try {
              //  console.log(data);

                var response = JSON.parse(data);
                if (response && response.Status.toUpperCase() == "SUCCESS") {

                    if (response.Method == "SYNCUSERDATA") {
                        //console.log("HandleSyncResponse",response);
                        HandleSyncResponse(response);
                    }
                    if (response.Method == "SYNCMIRRACLEDATA") {
                        HandleMirracleSyncResponse(response);
                    }
                    if(response.Method == "CONNECTCALL"){
                        connectCallback(response);
                    }
                    else if (response.Method == "PINNACLEAIASSISTANT") {
                        globalPinnacleAssistant(response);
                    }
                    else if (response.Method == "SENDWHATSAPPMESSAGE") {
                        globalSendWhatsappCallBack(response);
                    }
                    else if (response.Method == "GETACTIVITIES") {
                        HandleGetActivitiesResponse(response);
                    }
                    else if (response.Method == "DATACREATION") {
                        HandleDataCreationResponse(response);
                    }
                    else if (response.Method == "GETAMOUNTSFORROYALTY") {
                        HandleCustomDataResponse(response);
                    }
                    else if (response.Method == "GETUSERCUSTOMDATA") {
                        HandleUserCustomDataResponse(response);
                    }
                    else if (response.Method == "GETCUSTOMLEADHISTORY") {
                        HandleCustomLeadDataResponse(response);
                    }
                    else if (response.Method == "DATABATCHUPDATION") {
                        HandleBatchDataUpdationResponse(response);
                    }
                    else if (response.Method == "GETAGGREEGATES") {
                        HandleGetAggreegatesResponse(response);
                    }
                    else if (response.Method == "GETUSERS") {
                        HandleGetUsersResponse(response);
                    }
                    else if (response.Method == "GETLEADSDATA") {
                        HandleLeadsDataResponse(response);
                    }
                    else if (response.Method == "GETPROFILECARDDATA") {
                        HandleProfileCardDataResponse(response);
                    }
                    else if (response.Method == "GETUSERINFO") {
                        HandleUserInfoDataResponse(response);
                    }
                    else if (response.Method == "GETLEADHISTORY") {
                        HandleLeadHistoryDataResponse(response);
                    }
                    else if (response.Method == "CREATEORUPDATEUSER") {
                        HandleUserCreationDataResponse(response);
                    }
                    else if (response.Method == "GETGROUPSDATA") {
                        HandleGroupsDataResponse(response);
                    }
                    else if (response.Method == "SENDOTP") {
                        HandleSendOTPResponse(response);
                    }
                    else if (response.Method == "VALIDATEOTP") {
                        HandleValidateOTPResponse(response);
                    }
                    else if (response.Method == "GETMIRRACLESDATA") {
                        globalMirraclesDataCallBack(response);
                    }
                    else if (response.Method == "GETDATABYID") {
                        globalDataByIdCallBack(response);
                    }
                    else if (response.Method == "GETCOURSEDATA") {
                        globalCourseDataCallBack(response);
                    }
                    else if (response.Method == "GETPINNACLEGIFTVOUCHERDATA") {
                        globalGiftVoucherCallBack(response);
                    }
                    
                    else if (response.Method == "GETMIRRACLESBYTYPEDATA") {
                        globalMirracleByTypeDataCallBack(response);
                    }
                    else if (response.Method == "GETMIRAVERSELANDINGDATA") {
                        globalMiraVerseLandingDataCallBack(response);
                    }
                    else if (response.Method == "GETPAYSLIP" && globalPayslipCallBack) {
                        globalPayslipCallBack(response);
                    }
                    else if (response.Method == "GETSALARYSTATEMENT" && globalSalaryCallBack) {
                        globalSalaryCallBack(response);
                    }
                    else if (response.Method == "GETCLEAVES" && globalCLeavesCallBack) {
                        globalCLeavesCallBack(response);
                    }
                    else if (response.Method == "GETAVAILABLESLOTS" && globalAvailableslotsCallBack) {
                        globalAvailableslotsCallBack(response);
                    } 
                    else if (response.Method == "GETGRACECOMPENSATION" && globalGraceCompos) {
                        globalGraceCompos(response);
                    }
                    else if (response.Method == "GETPINNACLETVDEVICES" && globalGetPinnacleTvDevices) {
                        globalGetPinnacleTvDevices(response);
                    }
                    else if (response.Method == "GETOTPANALYTICS" && globalOtpAnalytics) {
                        globalOtpAnalytics(response);
                    }
                    else if (response.Method == "BIOMETRICDATA") {
                        globalBiometricData(response);
                    }
                    else if (response.Method == "GETGOALSCOMPLETEDDATA" && globalGoalsCompletedCallBack) {
                        globalGoalsCompletedCallBack(response);
                    }
                    else if (response.Method == "DATADELETION" && globalDataDeletionCallBack) {
                        globalDataDeletionCallBack(response);
                    }
                    else if (response.Method == "GETNUTUREDATA" && globalNatureCallBack) {
                        globalNatureCallBack(response);
                    }
                    else if (response.Method == "GETKUBERADATA" && globalKuberaCallBack) {
                        globalKuberaCallBack(response);
                    }
                    else if (response.Method == "GETQUERYBASEDDATA" && globalQueryBasedDataCallBack) {
                        globalQueryBasedDataCallBack(response);
                    }
                    else if (response.Method == "KUBERAPAYMENTS" && globalKuberaPaymentsCallBack) {
                        globalKuberaPaymentsCallBack(response);
                    }
                    else if (response.Method == "GETMIRRACLESFORCONFIG" && globalMConfigCallBack) {
                        globalMConfigCallBack(response);
                    }
                    else if (response.Method == "GETMIRRACLESREPORT" && globalMReportCallBack) {
                        globalMReportCallBack(response);
                    }
                    else if (response.Method == "GETMIRRACLESREPORTDATA" && globalMReportDataCallBack) {
                        globalMReportDataCallBack(response);
                    }

                    else if (response.Method == "REMOVEFAMILY" && globalRemoveFamilyCallBack) {
                        globalRemoveFamilyCallBack(response);
                    }
                    else if (response.Method == "UNLOCKACCOUNT" && globalResponseUnlockAccountCallBack) {
                        globalResponseUnlockAccountCallBack(response);
                    }

                   else if (response.Method == "MIRRACLELOADMORE" && globalResponseMirraclesLoadMore) {
                        alreadyLoadMoreCallIsGoing = false;
                        globalResponseMirraclesLoadMore(response);
                    }
else if(response.Method == "MIRRACLESEARCH" && globalResponseMirraclesSearch)
{
 globalResponseMirraclesSearch(response);
}
                }
                else if (response && response.Status.toUpperCase() == "ERROR") {
                   
                    if (response.Method == "SYNCUSERDATA" && globalSyncCallBack) {

                        $(".sync-refresh").removeClass("active");
                        isSyncing = false;
                        globalSyncCallBack(null, response);
                    }//SYNCMIRRACLEDATA
                    if (response.Method == "SYNCMIRRACLEDATA" && globalMirracleSyncCallBack) {
                        isMirracleSyncing = false;
                        globalMirracleSyncCallBack(null, response);
                    }
                    else if(response.Method == "CONNECTCALL"){
                        connectCallback(null,response);
                    }
                    else if (response.Method == "PINNACLEAIASSISTANT") {
                        globalPinnacleAssistant(null,response);
                    }
                    else if(response.Method == "BIOMETRICDATA"){
                        getBiometricData(null,response);
                    }
                    else if (response.Method == "UNLOCKACCOUNT" && globalResponseUnlockAccountCallBack) {
                        globalResponseUnlockAccountCallBack(null,response);
                    }
                    else if (response.Method == "MIRRACLELOADMORE" && globalResponseMirraclesLoadMore) {
                        alreadyLoadMoreCallIsGoing = false;
                        globalResponseMirraclesLoadMore(null,response);
                    }
else if(response.Method == "MIRRACLESEARCH" && globalResponseMirraclesSearch)
{
 globalResponseMirraclesSearch(null,response);
}
                    else if (response.Method == "GETAVAILABLESLOTS" && globalAvailableslotsCallBack) {
                        globalAvailableslotsCallBack(null,response);
                    }
                    else if (response.Method == "GETGRACECOMPENSATION" && globalGraceCompos) {
                        globalGraceCompos(null,response);
                    }
                    else if (response.Method == "GETPINNACLETVDEVICES" && globalGetPinnacleTvDevices) {
                        globalGetPinnacleTvDevices(null,response);
                    }
                    else if (response.Method == "GETOTPANALYTICS" && globalOtpAnalytics) {
                        globalOtpAnalytics(response);
                    }
                    else if (response.Method == "BIOMETRICDATA") {
                        globalBiometricData(response);
                    }
                    else if (response.Method == "GETPAYSLIP" && globalPayslipCallBack) {
                        globalPayslipCallBack(null, response);
                    }
                    else if (response.Method == "GETSALARYSTATEMENT" && globalSalaryCallBack) {
                        globalSalaryCallBack(null, response);
                    }
                    else if (response.Method == "GETCLEAVES" && globalCLeavesCallBack) {
                        globalCLeavesCallBack(null,response);
                    }
                    else if (response.Method == "SENDWHATSAPPMESSAGE") {
                        globalSendWhatsappCallBack(null, response);
                    }
                    else if (response.Method == "GETMIRRACLESDATA") {
                        globalMirraclesDataCallBack(response);
                    }
                    else if (response.Method == "GETDATABYID") {
                        globalDataByIdCallBack(response);
                    }
                    else if (response.Method == "GETACTIVITIES" && globalActivitiesCallBack) {
                        globalActivitiesCallBack(null, response);
                    }
                    else if (response.Method == "GETCOURSEDATA") {
                        globalCourseDataCallBack(response);
                    }
                    else if (response.Method == "GETPINNACLEGIFTVOUCHERDATA") {
                        globalGiftVoucherCallBack(null,response);
                    }
                    else if (response.Method == "GETMIRRACLESBYTYPEDATA") {
                        globalMirracleByTypeDataCallBack(response);
                    }
                    else if (response.Method == "GETMIRAVERSELANDINGDATA") {
                        globalMiraVerseLandingDataCallBack(response);
                    }
                        //
                    else if (response.Method == "GETMIRRACLESFORCONFIG" && globalMConfigCallBack) {
                        globalMConfigCallBack(null, response);
                    }
                    else if (response.Method == "GETMIRRACLESREPORT" && globalMReportCallBack) {
                        globalMReportCallBack(null, response);
                    }
                    else if (response.Method == "GETMIRRACLESREPORTDATA" && globalMReportDataCallBack) {
                        globalMReportDataCallBack(null, response);
                    }
                    else if (response.Method == "DATACREATION" && globalDataCreationCallBack) {
                        globalDataCreationCallBack(null, response);
                    }
                    else if (response.Method == "GETAMOUNTSFORROYALTY") {
                        globalCustomRoyaltyCallBack(null,response);
                    }
                    else if (response.Method == "GETUSERCUSTOMDATA") {
                        globalUserCustomDataCallBack(null,response);
                    }
                    else if (response.Method == "GETCUSTOMLEADHISTORY") {
                        globalCustomLeadCallBack(null,response);
                    }
                    else if (response.Method == "DATABATCHUPDATION" && globalDataBatchUpdateCallBack) {
                        globalDataBatchUpdateCallBack(null, response);
                    }
                    //
                    else if (response.Method == "DATADELETION" && globalDataDeletionCallBack) {
                        globalDataDeletionCallBack(null, response);
                    }
                    else if (response.Method == "GETAGGREEGATES" && globalAggreegatesCallBack) {
                        globalAggreegatesCallBack(null, response);
                    }
                    else if (response.Method == "GETUSERS" && globalUsersDaataCallBack) {
                        globalUsersDaataCallBack(null, response);
                    }
                    else if (response.Method == "GETLEADSDATA" && globalUsersDaataCallBack) {
                        globalLeadsDataCallBack(null, response);
                    }
                    else if (response.Method == "GETPROFILECARDDATA" && globalProfileCardCallBack) {
                        globalProfileCardCallBack(null, response);
                    }
                    else if (response.Method == "GETUSERINFO" && globalUserInfoCallBack) {
                        globalUserInfoCallBack(null, response);
                    }
                    else if (response.Method == "GETLEADHISTORY" && globalLeadHistoryCallBack) {
                        globalLeadHistoryCallBack(null, response);
                    }
else if(response.Method == "GETGOALSCOMPLETEDDATA" && globalGoalsCompletedCallBack)
{
globalGoalsCompletedCallBack(null,response);
                    }
                    else if (response.Method == "GETNUTUREDATA" && globalNatureCallBack) {
                        globalNatureCallBack(null,response);
                    }
                    else if (response.Method == "GETKUBERADATA" && globalKuberaCallBack) {
                        globalKuberaCallBack(null,response);
                    }
                    else if (response.Method == "GETQUERYBASEDDATA" && globalQueryBasedDataCallBack) {
                        globalQueryBasedDataCallBack(null,response);
                    }
                    else if (response.Method == "KUBERAPAYMENTS" && globalKuberaPaymentsCallBack) {
                        globalKuberaPaymentsCallBack(null,response);
                    }
                    else if (response.Method == "CREATEORUPDATEUSER" && globalUserCreationCallBack) {
                        globalUserCreationCallBack(null, response);
                    }
                    else if (response.Method == "GETGROUPSDATA" && globalGroupsDataCallBack) {
                        globalGroupsDataCallBack(null, response);
                    }
                    else if (response.Method == "SENDOTP" && globalSendOTPCallBack) {
                        globalSendOTPCallBack(null, response);
                    }
                    else if (response.Method == "VALIDATEOTP" && globalValidateOTPCallBack) {
                        globalValidateOTPCallBack(null, response);
                    }
                    else if (response.Method == "REMOVEFAMILY" && globalRemoveFamilyCallBack) {
                        globalRemoveFamilyCallBack(null, response);
                    }

                    //Error object Preparing.                   
                    var errorObj = {};
                    errorObj.Type = 3;
                    errorObj.Message = response.Method + ":" + response.ErrorMsg + "\n\n" + response.StackTrace;
                    errorObj.CreatedDateTime = getCurrentDateTime();

                    SaveExceptionToSentry(errorObj, "Error", getUserToken(), "SocketEventListener");

                    if (response.Method == "FORCELOGOFF" && globalSyncCallBack) {
                        if (response.Type != "SILENT") {
                            clearUserRelatedData(true);
                        }
                        else {
                            clearUserRelatedData();
                        }
                    }

                    console.log(response);
                }
                else {
                    console.log(response);
                }
            } catch (err) {
                console.log("Error in Server Event Handler :: " + err);
            }
        });

        webSocket.addEventListener("close", (res) => {
            ShowToastMessage("Connection disconnected, Retrying...");
            try {
                if (failedCounter < 2) {
                    setTimeout(function () {
                        CheckAndMakeSocketConnection(function (res) {
                            if (res) {
                                ShowToastMessage("Connection established successfully");
                                callBack(true);
                            }
                            else {
                                ShowToastMessage("Unable to establish the socket connection");
                                callBack(false);
                            }
                        });
                    }, 1000);
                }
            } catch (err) {
                SaveCleintWaufli(err,"Socket Response Failed"," SocketResponse ");
       
                console.log(err);
            }
        });

        webSocket.addEventListener("onerror", (res) => {
            console.log('Closed websocket');
            callBack(false);
        });

    } catch (err) {
        SaveCleintWaufli(err,"Socket Response Failed"," SocketResponse ");
       
        console.log("Error in while establishing the socket connection :: " + err);
        callBack(false);
    }
}

function closeWebSocket() {
    if (webSocket) {
        webSocket.close();
    }
}

function getMyStore() {
    return appdataStore;
}

export function UpdateUserDataInCacheDBSocket(result, callback) {
   // return UpdateUserDataInCacheDB(result, callback);
    return UpdateLocalData(result, callback);

    //UpdateLocalData
}


export function UpdateDangDataInCacheDBSocket(result, callback) {
    return UpdateDangDataInCacheDB(result, callback);
}
//UpdateDangDataInCacheDBSocket
export function MakeSocketConnection(token, ds, callBack) {
    try {
        appdataStore = ds || appdataStore;
        //var token = dataStore.getState().AT;
        token = encodeURIComponent(token);
        const url = new URL("https://mirracle.pinnacleblooms.org/api/scoketapisql/?at=" + token);
        url.protocol = "wss";
        url.pathname = "/ws";
        websocket(url, callBack);
    } catch (e) {
        console.log("Error in establishConnection : " + e);
    }
}

export function CheckAndMakeSocketConnection(callBack, isSilent) {
    try {
        if (!HandleOffline(function () {
            CheckAndMakeSocketConnection(callBack)
        }, isSilent)) {
            //callback(null, getSocketErrorResponse("No internect connection, Please check and try again."));
            return;
        }

        if (webSocket && webSocket.readyState == 1) {
            failedCounter = 0;
            callBack(true);
        }
        else {
            if (failedCounter >= 2) {
                callBack(null, getSocketErrorResponse("Unable to establish the socket connection, Please try again."));
                return;
            }

            failedCounter++;

            var token = cookie.load('validatorauth-s');
            if (!token) {
                token = localStorage.getItem("validatorauth-s");
            }

            MakeSocketConnection(token, appdataStore, callBack);
        }
    } catch (err) {
        console.log("Error in CheckAndMakeSocketConnection : " + err);
    }
}



export function DataDeletion(UId, callback,DeleteReason) {
    try {

        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalDataDeletionCallBack = callback || globalDataDeletionCallBack;

            var request = {};
            request.Method = "DATADELETION";
            request.Id = UId;
            request.DeleteReason = DeleteReason;
           

            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    }
    catch (err) {
        console.log("Error in DataCreation :: " + err);
    }
}


export function MirraclesSync(skipcount, callback, isSilent) {
    try {
       
        console.log("Mirracle Sync Started");
        var lsd = localStorage.getItem("LastMirracleCFSyncDataTime");
        var configJsonString = localStorage.getItem("UserSettings");

       

        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalMirracleSyncCallBack = callback || globalMirracleSyncCallBack;
            skipcount = skipcount || 0;

            var request = {};
            request.SkipCount = skipcount;
            request.Method = "SYNCMIRRACLEDATA";
            request.CT = GetClientType();
            request.LSD = lsd || "";
            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
        }, isSilent);
    }
    catch (err) {
        SaveExceptionToSentry(err, "Client MirraclesSync Error", "", JSON.stringify(err))
        console.log("Error in MirraclesSync :: " + err);
    }
}

export function ConnectCallTWebSocket(userid,callback,isSilent,type) {
    try {
        type = type ? type : 'NORMAL'
        console.log("Mirracle Sync Started");

       

        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            connectCallback = callback || connectCallback;
           // skipcount = skipcount || 0;

            var request = {};
            request.Method = "CONNECTCALL";
            request.UserId =userid;
            request.Type = type;
           
            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
        }, isSilent);
    }
    catch (err) {
        SaveExceptionToSentry(err, "Client MirraclesSync Error", "", JSON.stringify(err))
        console.log("Error in MirraclesSync :: " + err);
    }
}

export function PinnacleAISocketCall(prompt,threadId,purpose,kidId,ServiceId,month,CenterId,callback)
{
    

    try {
        //if (isSyncing) {
        //    return;
        //}
       

      
      
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            $(".sync-refresh").addClass("active");

            globalPinnacleAssistant = callback || globalPinnacleAssistant;



            var request = {};
            request.Method = "PINNACLEAIASSISTANT";
            request.CT = GetClientType();
            request.ThreadId = threadId;
            request.Prompt = prompt;
            request.Purpose = purpose;
            request.KidId = kidId;
            request.ServiceId = ServiceId;
            request.CenterId = CenterId;
            request.Month = month;
            
            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
        });
    }
    catch (err) {
        isSyncing = false;
        $(".sync-refresh").removeClass("active");
        SaveExceptionToSentry(err, "Client Sync Error", "", JSON.stringify(err))
        console.log("Error in SocketSync :: " + err);
    }
}

export function SocketSync(skipcount, callback, isSilent, forceRefresh,isUpdate) {
    try {
        //if (isSyncing) {
        //    return;
        //}
        isSyncing = true;

        isUpdate = isUpdate || false;
        console.log("Sync Started");
        
        var MIRRACLEFULLSYNCALERT = false;
        
       
        var lsd = localStorage.getItem("LastPSSyncDataTime");
        var configJsonString = localStorage.getItem("UserSettings");


       

        if (!forceRefresh && configJsonString && lsd) {
            var settings = JSON.parse(configJsonString).filter(function (e) { return e.Name == "ArchivalInterval" });

            var diffInMin = getMinutesBetweenDates(convertDateToUTC(new Date(lsd)), getCurrentDateTime());

        
            if (settings && settings.length > 0 && diffInMin < parseInt(settings[0].F1)) {
                callback({ Data: [] }, null);
                console.log("Sync time not elapsed : " + diffInMin + " : Config Sync Interval : " + settings[0].F1);
                return;
            }
        }

       // var dataStore = getStore();
        //var stateData = dataStore.getState();
        //if (null != lsd && (!stateData || !stateData.UD || !stateData.UD.Data || stateData.UD.Data.length == 0)) {
           // MIRRACLEFULLSYNCALERT = true;
            //lsd = null;
            try {
                //var token = getUserToken();
                //var exception = new Object();
                //exception.Type = "MISS HANDLING CLIENT DATA";
               // exception.Message = "MISS HANDLING CLIENT " + token;
                //SaveExceptionToSentry(exception, "MISS HANDLING CLIENT " + token, token, "MISS HANDLING CLIENT " + token);
            } catch (e) {

            }
     //   }
        var settingsSWV = "";
        if (configJsonString) {
            try {
            var settingsSW = JSON.parse(configJsonString).filter(function (e) { return e.Name == "SWVERSION" });
            if (settingsSW && settingsSW.length > 0) {
                settingsSWV = settingsSW[0].Value;
                }
            } catch (e) {

            }
        }
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                isSyncing = false;
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            $(".sync-refresh").addClass("active");

            globalSyncCallBack = callback || globalSyncCallBack;


            skipcount = skipcount || 0;

            var request = {};
            request.SkipCount = skipcount;
            request.Method = "SYNCUSERDATA";
            request.CT = GetClientType();
            request.LSD = lsd || "";
            request.AGTYPE = "NEW";
            request.LSDValues = GetTablesSyncDateTime();
            request.SW = window.swVersion + " USERSETTINGS: " + settingsSWV;
            request.isUpdate = isUpdate;
            console.log(request);
            console.log("socket request");
            startDateOfSyncHandling = new Date();
            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
        }, isSilent);
    }
    catch (err) {
        isSyncing = false;
        $(".sync-refresh").removeClass("active");
        SaveExceptionToSentry(err, "Client Sync Error", "", JSON.stringify(err))
        console.log("Error in SocketSync :: " + err);
    }
}

export function GetActivitiesByType(types, skipcount, callback, lsd, isArchival, toDate, fIds) {
    try {

        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            console.log("GetActivitiesByType");

            globalActivitiesCallBack = callback || globalActivitiesCallBack;

            skipcount = skipcount || 0;
            isArchival = isArchival || false;

            var request = {};
            request.SkipCount = skipcount;
            request.Method = "GETACTIVITIES";
            request.LSD = lsd || "";
            request.Types = types;
            request.IsArchival = isArchival;
            request.ToDate = toDate;
            request.FIds = fIds;

            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    }
    catch (err) {
        console.log("Error in GetActivitiesByType :: " + err);
    }
}

export function GetMirraclesForConfig(skipcount,TId,callback) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            console.log("GetMirraclesForConfig");

            globalMConfigCallBack = callback || globalMConfigCallBack;

            skipcount = skipcount || 0;
            TId = TId || "";
           

            var request = {};
            request.SkipCount = skipcount;
            request.TId = TId;

            request.Method = "GETMIRRACLESFORCONFIG";
           

            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    } catch (e) {
        console.log("Error in GetMirraclesForConfig :: " + e);
    }
}

export function GetMirraclesReport(FT,FN,callback) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            console.log("GetMirraclesReport");

            globalMReportCallBack = callback || globalMReportCallBack;

           // skipcount = skipcount || 0;


            var request = {};
            //request.SkipCount = skipcount;
            request.Method = "GETMIRRACLESREPORT";
            if (FT) {
                request.FT = FT;
                request.FN = FN;
            }

            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    } catch (e) {
        console.log("Error in GetMirraclesReport :: " + e);
    }
}

export function GetMirraclesReportData(level,levelname,callback) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            console.log("GetMirraclesReportData");

            globalMReportDataCallBack = callback || globalMReportDataCallBack;

            // skipcount = skipcount || 0;


            var request = {};
            request.Level = level;
            request.LevelName = levelname;
            request.Method = "GETMIRRACLESREPORTDATA";


            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    } catch (e) {
        console.log("Error in GetMirraclesReportData :: " + e);
    }
}



export function DataCreation(data, callback, analytic,type,analyticType,userObj) {
    try {

        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalDataCreationCallBack = callback || globalDataCreationCallBack;

            var request = {};
            request.Method = "DATACREATION";
            request.Data = data;
            request.Analytic = analytic;
            request.Type = type;
            request.AnalyticType = analyticType;
            request.UserObj = userObj;
            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    }
    catch (err) {
        console.log("Error in DataCreation :: " + err);
    }
}

export function GETAMOUNTSFORROYALTY(data, callback){
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalCustomRoyaltyCallBack = callback || globalCustomRoyaltyCallBack;

            var request = {};
            request.Method = "GETAMOUNTSFORROYALTY";
            request.Data = data;
            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    } catch (error) {
        console.log("Error in GETAMOUNTSFORROYALTY :: " + error);
    }
}

export function GETUSERCUSTOMDATA(data, callback){
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalUserCustomDataCallBack = callback || globalUserCustomDataCallBack;

            var request = {};
            request.Method = "GETUSERCUSTOMDATA";
            request.Data = data;
            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    } catch (error) {
        console.log("Error in GETUSERCUSTOMDATA :: " + error);
    }
}

export function GETCUSTOMLEADHISTORY(data, callback){
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalCustomLeadCallBack = callback || globalCustomLeadCallBack;

            var request = {};
            request.Method = "GETCUSTOMLEADHISTORY";
            request.Data = data;
            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    } catch (error) {
        console.log("Error in GETCUSTOMLEADHISTORY :: " + error);
    }
}


export function DataBatchUpdation(data,callback, type, userObj) {
    try {

        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalDataBatchUpdateCallBack = callback || globalDataBatchUpdateCallBack;

            var request = {};
            request.Method = "DATABATCHUPDATION";
            request.Data = data;
            request.Type = type;
            request.UserObj = userObj;
            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    }
    catch (err) {
        console.log("Error in DataCreation :: " + err);
    }
}

export function GetUsersData(userId, types, skipCount, callback, fid) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalUsersDaataCallBack = callback || globalUsersDaataCallBack;

            var request = {};
            request.Method = "GETUSERS";
            request.UId = userId;
            request.Types = types;
            request.FId = fid;
            request.SkipCount = skipCount;
            request.TakeCount = 1000;


            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}

export function GetLeadsData(groups, skipcount, callback) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalLeadsDataCallBack = callback || globalLeadsDataCallBack;

            var request = {};
            request.Method = "GETLEADSDATA";
            request.Groups = groups;
            request.SkipCount = skipcount;

            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}

export function GETMIRRACLESDATA(callback) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalMirraclesDataCallBack = callback || globalQueryBasedDataCallBack;

            var request = {};
            request.Method = "GETMIRRACLESDATA";
            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                callback(null, { Status: "ERROR", Retry: true });
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}

export function GetDataById(id, callback) {
    // else if (response.Method == "GETDATABYID") {
    //    globalDataByIdCallBack(response);
    //}

    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalDataByIdCallBack = callback || globalDataByIdCallBack;

            var request = {};
            request.Method = "GETDATABYID";
            request.Id = id + "";
            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                callback(null, { Status: "ERROR", Retry: true });
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}


export function MirraclesInitialLoad(callback)
{
       var key =  mirracleMoreKey();
       var currentKey = localStorage.getItem("CurrentMirraclesDataTime");
       if(key != currentKey)
       {
          MirraclesLoadMore(0,window.totalMirraclesCount, callback);
       }

}


export function MirraclesLoadMore(SkipCount,TakeCount, callback) {
    try {

if(alreadyLoadMoreCallIsGoing)
{return;
}
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }


           
        
            globalResponseMirraclesLoadMore = callback || globalResponseMirraclesLoadMore;
            var request = {};
            request.Method = "MIRRACLELOADMORE";
            request.SkipCount = SkipCount;
            request.TakeCount = TakeCount;

            if (webSocket != null && webSocket.readyState == 1) {
                 alreadyLoadMoreCallIsGoing = true;
                webSocket.send(JSON.stringify(request));
            }
            else {
                callback(null, { Status: "ERROR", Retry: true });
            }
        });
    }
    catch (err) {
        console.log("Error in UnlockAccount :: " + err);
    }
}


export function MirraclesSearch(languages,categories,departments,facilities,SkipCount,TakeCount, callback) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }


            globalCourseDataCallBack = callback || globalQueryBasedDataCallBack;

            globalResponseMirraclesSearch = callback || globalResponseMirraclesSearch;
            var request = {};
            request.Method = "MIRRACLESEARCH";
            request.SkipCount = SkipCount;
 request.Languages = languages;
 request.Departments = departments;
 request.Categories = categories;
 request.Facilities = facilities;
            request.TakeCount = TakeCount;
            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                callback(null, { Status: "ERROR", Retry: true });
            }
        });
    }
    catch (err) {
        console.log("Error in UnlockAccount :: " + err);
    }
}

export function ValidatePinnacleGiftVoucher(code, fid, ptye, bossId, callback) {

    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

           
            globalGiftVoucherCallBack = callback || globalQueryBasedDataCallBack;
            var request = {};
            request.Method = "GETPINNACLEGIFTVOUCHERDATA";
            request.BossId = bossId;
            request.FId = fid;
            request.Code = code;
            request.PType = ptye;
            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                callback(null, { Status: "ERROR", Retry: true });
            }
        });
    } catch (err) {
        console.log("Error in GetCourseData :: " + err);
    }
}


export function GetCourseData(CourseId, OGID, callback) {

    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }
            globalCourseDataCallBack = callback || globalQueryBasedDataCallBack;
            var request = {};
            request.Method = "GETCOURSEDATA";
            request.CourseId = CourseId;
            request.OGID = OGID;
            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                callback(null, { Status: "ERROR", Retry: true });
            }
        });
    } catch (err) {
        console.log("Error in GetCourseData :: " + err);
    }
}

export function GetMirraclesByType(department, type, personid, language, mname, callback) {

    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalMirracleByTypeDataCallBack = callback || globalQueryBasedDataCallBack;

            var request = {};
            request.Method = "GETMIRRACLESBYTYPEDATA";
            request.Department = department;
            request.Type = type;
            request.PersonId = personid;
            request.Language = language;
            request.MName = mname;

            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                callback(null, { Status: "ERROR", Retry: true });
            }
        });
    } catch (err) {
        console.log("Error in GetMirraclesByType :: " + err);
    }
}

export function GetMiraVerseLandingData(callback) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            

            globalMiraVerseLandingDataCallBack = callback || globalQueryBasedDataCallBack;

            var request = {};
            request.Method = "GETMIRAVERSELANDINGDATA";
            
            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                callback(null, { Status: "ERROR", Retry: true });
            }
        });
    } catch (err) {
        console.log("Error in GetMirraclesByType :: " + err);
    }
}

export function UnlockAccount(OGID,reamrks, callback) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalResponseUnlockAccountCallBack = callback || globalResponseUnlockAccountCallBack;
            var request = {};
            request.Method = "UNLOCKACCOUNT";
            request.OGID = OGID;
            request.Remarks = reamrks;
            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                callback(null, { Status: "ERROR", Retry: true });
            }
        });
    }
    catch (err) {
        console.log("Error in UnlockAccount :: " + err);
    }
}


export function GETQUERYBASEDDATA(request, callback) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalQueryBasedDataCallBack = callback || globalQueryBasedDataCallBack;
           

            request.Method = "GETQUERYBASEDDATA";
            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                callback(null, { Status: "ERROR", Retry: true });
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}

export function GetSocketKuberaData(callback, paymentscallback) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }


           
            globalKuberaCallBack = callback || globalKuberaCallBack;
            globalKuberaPaymentsCallBack = paymentscallback || globalKuberaPaymentsCallBack;


            var request = {};
            request.Method = "GETKUBERADATA";


            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                callback(null, { Status: "ERROR", Retry: true });
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}

export function GetSocketNatureData(callback) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }
            globalNatureCallBack = callback || globalNatureCallBack;

           

            var request = {};
            request.Method = "GETNUTUREDATA";
          

            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                callback(null, { Status: "ERROR", Retry: true });
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}
export function SendSocketWhatsappMessage(paremters,ogid,template,callback) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }
            globalSendWhatsappCallBack = callback || globalSendWhatsappCallBack;

            var request = {};
            request.Method = "SENDWHATSAPPMESSAGE";
            request.Parameters = paremters;
            request.OGID = ogid;
            request.TemplateName = template;
            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                callback(null, { Status: "ERROR", Retry: true });
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}

export function GetPinnacleTvDevices(Code,DId,isManual,callback) {
    try {

        failedCounter = 0;

        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalGetPinnacleTvDevices = callback || globalGetPinnacleTvDevices;

            

            var request = {};
            request.Method = "GETPINNACLETVDEVICES";
            request.Type = "pinnacledevices";
            request.Code = Code;
            request.DId = DId;
            request.IsManual = isManual ? isManual : false;
            request.Auth = getUserToken();
           // request.Date = date;
            // request.Date = date;


            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                failedCounter = 0;
                CheckAndMakeSocketConnection(function (res) {
                    if (!res) {
                        callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                        return;
                    }

                    if (webSocket != null && webSocket.readyState == 1) {
                        webSocket.send(JSON.stringify(request));
                    }
                });
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}


export function GetGraceCompoAttendance(kidId,ServiceId,callback,tId) {
    try {
        failedCounter = 0;

        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalGraceCompos = callback || globalGraceCompos;

            

            var request = {};
            request.Method = "GETGRACECOMPENSATION";
            request.Type = "gracecompensation";
            request.Id = kidId ;
            request.ServiceId = ServiceId;
           // request.Date = date;
            // request.Date = date;


            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                failedCounter = 0;
                CheckAndMakeSocketConnection(function (res) {
                    if (!res) {
                        callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                        return;
                    }

                    if (webSocket != null && webSocket.readyState == 1) {
                        webSocket.send(JSON.stringify(request));
                    }
                });
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}




export function GetAvailableSlotsByTherapist(service,from,to,callback,tId) {
    try {
        failedCounter = 0;

        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalAvailableslotsCallBack = callback || globalAvailableslotsCallBack;

            

            var request = {};
            request.Method = "GETAVAILABLESLOTS";
            request.Type = "availableslotsbytherapist";
            request.FId = service.FId;
            request.ServiceType = service.RDT;
            request.Id = tId ? tId : service.TherapistId;
            request.From = from;
            request.To = to;
            request.TId = tId ? tId : service.TherapistId;
            request.TherapistName = service.F3;
           // request.Date = date;
            // request.Date = date;


            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                failedCounter = 0;
                CheckAndMakeSocketConnection(function (res) {
                    if (!res) {
                        callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                        return;
                    }

                    if (webSocket != null && webSocket.readyState == 1) {
                        webSocket.send(JSON.stringify(request));
                    }
                });
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}

export function GetAvailableSlots(callback) {
    try {
        failedCounter = 0;
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalAvailableslotsCallBack = callback || globalAvailableslotsCallBack;

            var request = {};
            request.Method = "GETAVAILABLESLOTS";
            request.Type = "availableSlots";
           // request.Date = date;


            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                failedCounter = 0;
                CheckAndMakeSocketConnection(function (res) {
                    if (!res) {
                        callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                        return;
                    }

                    if (webSocket != null && webSocket.readyState == 1) {
                        webSocket.send(JSON.stringify(request));
                    }
                });
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}


export function GetOtpAnalytics(data,callback) {
    try {
        failedCounter = 0;
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalOtpAnalytics = callback || globalOtpAnalytics;

            var request = {};
            request.Method = "GETOTPANALYTICS";
            //request.Type = "availableSlots";
            // request.Date = date;


            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                failedCounter = 0;
                CheckAndMakeSocketConnection(function (res) {
                    if (!res) {
                        callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                        return;
                    }

                    if (webSocket != null && webSocket.readyState == 1) {
                        webSocket.send(JSON.stringify(request));
                    }
                });
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}


export function getBiometricData(data,fdt,tdt,callback) {
    try {
        failedCounter = 0;
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalBiometricData = callback || globalBiometricData;

            var request = {};
            request.Method = "BIOMETRICDATA";
            request.FDT=fdt
            request.TDT=tdt
            //request.Type = "availableSlots";
            // request.Date = date;


            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                failedCounter = 0;
                CheckAndMakeSocketConnection(function (res) {
                    if (!res) {
                        callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                        return;
                    }

                    if (webSocket != null && webSocket.readyState == 1) {
                        webSocket.send(JSON.stringify(request));
                    }
                });
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}

export function GetCLeaves(date, callback) {
    try {
        failedCounter = 0;
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalCLeavesCallBack = callback || globalCLeavesCallBack;

            var request = {};
            request.Method = "GETCLEAVES";

            request.Date = date;
            

            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                failedCounter = 0;
                CheckAndMakeSocketConnection(function (res) {
                    if (!res) {
                        callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                        return;
                    }

                    if (webSocket != null && webSocket.readyState == 1) {
                        webSocket.send(JSON.stringify(request));
                    }
                });
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}

export function GetSalaryStatement(M, Y, Fid, callback) {
    try {
        failedCounter = 0;
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalSalaryCallBack = callback || globalSalaryCallBack;

            var request = {};
            request.Method = "GETSALARYSTATEMENT";
            
            request.M = M;
            request.Y = Y;
            request.FId = Fid;

            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                failedCounter = 0;
                CheckAndMakeSocketConnection(function (res) {
                    if (!res) {
                        callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                        return;
                    }

                    if (webSocket != null && webSocket.readyState == 1) {
                        webSocket.send(JSON.stringify(request));
                    }
                });
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}

export function GetPaySlip(OGID,M,Y,Fid, callback) {
    try {
        failedCounter = 0;
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalPayslipCallBack = callback || globalPayslipCallBack;

            var request = {};
            request.Method = "GETPAYSLIP";
            request.OGID = OGID;
            request.M = M;
            request.Y = Y;
            request.FId = Fid;

            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                failedCounter = 0;
                CheckAndMakeSocketConnection(function (res) {
                    if (!res) {
                        callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                        return;
                    }

                    if (webSocket != null && webSocket.readyState == 1) {
                        webSocket.send(JSON.stringify(request));
                    }
                });
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}


export function GetLeadHistory(leadId, callback, type) {
    try {
        failedCounter = 0;
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalLeadHistoryCallBack = callback || globalLeadHistoryCallBack;

            var request = {};
            request.Method = "GETLEADHISTORY";
            request.LeadId = leadId;
            request.Type = type ? type.toUpperCase() : "LEAD";

            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                failedCounter = 0;
                CheckAndMakeSocketConnection(function (res) {
                    if (!res) {
                        callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                        return;
                    }

                    if (webSocket != null && webSocket.readyState == 1) {
                        webSocket.send(JSON.stringify(request));
                    }
                });
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}


export function GetGoalsCompletedData(month,year,fid, callback) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalGoalsCompletedCallBack = callback || globalGoalsCompletedCallBack;
            var request = {};
            request.Method = "GETGOALSCOMPLETEDDATA";
            
            request.Month = month;
            request.Year = year;
            request.FId = fid;
            if (webSocket != null && webSocket.readyState == 1) {
                webSocket.send(JSON.stringify(request));
            }
            else {
                callback(null, { Status: "ERROR", Retry: true });
            }
        });
    }
    catch (err) {
        console.log("Error in GetUsersData :: " + err);
    }
}

export function GetAggreegates(month, year,isfacilityConsider,fIds, callback) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalAggreegatesCallBack = callback || globalAggreegatesCallBack;

            var request = {};
            request.Method = "GETAGGREEGATES";
            request.Month = month;
            request.Year = year;
            request.IsfacilityConsider = isfacilityConsider;
            request.FIds = fIds;
            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    }
    catch (err) {
        console.log("Error in GetAggreegates :: " + err);
    }
}

export function GetProfileCardData(groupId, callback) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalProfileCardCallBack = callback || globalProfileCardCallBack;

            var request = {};
            request.Method = "GETPROFILECARDDATA";
            request.GroupId = groupId;

            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    }
    catch (err) {
        console.log("Error in GetProfileCardData :: " + err);
    }
}

export function GetUserInfoData(groupId, callback) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalUserInfoCallBack = callback || globalUserInfoCallBack;

            var request = {};
            request.Method = "GETUSERINFO";
            request.GroupId = groupId;

            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    }
    catch (err) {
        console.log("Error in GetUserInfoData :: " + err);
    }
}

export function CreateOrUpdateUser(data, isNewUser, callback, userType) {
    try {

        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }




            globalUserCreationCallBack = callback || globalUserCreationCallBack;

            //var testobj = { "AT": "User", "RDT": "Nawin", "S": "male", "FId": "2285833408", "FDT": "1993-05-25T00:00:00.000Z", "TDT": "2021-07-26T00:00:00.000Z", "F1": "12334234324", "F4": "NAVEEN@GMAIL.COM,NAVEEN1@GMAIL.COM", "F5": "THERAPIST{#}{#}505001{#}131212312234{#}123123123{#}500055{#}2021-07-31{#}10000", "F7": "25000", "F6": "KARIMNAGAR{#}HYDERABAD", "F10": "12312312312" }
            try {
                delete data['IsRecordFrom'];
                delete data['Token'];
            } catch (e) {

            }
            


            var request = {};
            request.Method = "CREATEORUPDATEUSER";
            request.Data = data;
            request.IsNewUser = isNewUser;
            request.Type = userType ? userType : "NONE";

            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    }
    catch (err) {
        console.log("Error in CreateOrUpdateUser :: " + err);
    }
}

export function GetGroupsData(groupId, isPublsih, skipCount, callback) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callback(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalGroupsDataCallBack = callback || globalGroupsDataCallBack;

            var request = {};
            request.Method = "GETGROUPSDATA";
            request.GroupId = groupId;
            request.IsPublish = isPublsih;
            request.SkipCount = skipCount || 0;

            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    }
    catch (err) {
        console.log("Error in GetGroupsData :: " + err);
    }
}

export function SendOTPSocket(mobileNumber, email, country, type, callBack) {
    try {
       
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callBack(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalSendOTPCallBack = callBack || globalSendOTPCallBack;

            var request = {};
            request.Method = "SENDOTP";
            request.MobileNumber = mobileNumber;
            request.Email = email;
            request.Country = country;
            request.Type = type ? type.split("{#}")[0] : "";
            request.Relation = type && type.split("{#}").length > 1 ? type.split("{#}")[1] : "";
            request.Name = type && type.split("{#}").length > 2 ? type.split("{#}")[2] : "";
            request.KidName = type && type.split("{#}").length > 3 ? type.split("{#}")[3] : "";
            request.PrimaryMobile = type && type.split("{#}").length > 4 ? type.split("{#}")[4] : "";

            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });

    } catch (err) {
        console.log("Error in SendOTPSocket : " + err);
        callBack(null, err);
    }
}

export function ValidateOTPSocket(mobileNumber, OTP, type, toId, callBack) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callBack(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalValidateOTPCallBack = callBack || globalValidateOTPCallBack;

            var request = {};
            request.Method = "VALIDATEOTP";
            request.MobileNumber = mobileNumber;
            request.OTP = OTP;
            request.Type = type ? type.split("{#}")[0] : "";;
            request.ToUserId = toId;
            request.Relation = type && type.split("{#}").length > 1 ? type.split("{#}")[1] : "";
            request.Name = type && type.split("{#}").length > 2 ? type.split("{#}")[2] : "";
            request.KidName = type && type.split("{#}").length > 3 ? type.split("{#}")[3] : "";
            request.PrimaryMobile = type && type.split("{#}").length > 4 ? type.split("{#}")[4] : "";

            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    } catch (err) {
        console.log("Error in SendOTPSocket : " + err);
        callBack(null, err);
    }
}


export function RemoveFamily(mobileNumber, toId, callBack) {
    try {
        CheckAndMakeSocketConnection(function (res) {
            if (!res) {
                callBack(null, getSocketErrorResponse("Unable to enstablish the socket connection."));
                return;
            }

            globalRemoveFamilyCallBack = callBack || globalRemoveFamilyCallBack;

            var request = {};
            request.Method = "REMOVEFAMILY";
            request.MobileNumber = mobileNumber;
            request.ToUserId = toId;
           
            if (webSocket != null) {
                webSocket.send(JSON.stringify(request));
            }
        });
    } catch (err) {
        console.log("Error in SendOTPSocket : " + err);
        callBack(null, err);
    }
}


export function SendOTPS(mobileNumber, email, country, callBack) {
    try {
        $.ajax({
            url: "https://www.pinnacleblooms.org/api/sendotp?id=" + mobileNumber + "&tag=" + country + "&emailId" + email,
            type: "GET",
            dataType: "json",
            success: function (result) {
                try {
                    callBack(result);
                } catch (e) {
                    console.log(e);
                }
            },
            error: function (x, y, z) {
                callBack(null, x);
            }
        });
    } catch (err) {
        console.log("Error in SendOTP : " + err);
        callBack(null, err);
    }
}

export function ValidateOTPS(mobileNumber, otp, callBack) {
    try {
        $.ajax({
            url: "https://www.pinnacleblooms.org/api/validateotp?mobileNumber=" + mobileNumber + "&OTP=" + otp,
            type: "GET",
            dataType: "json",
            success: function (result) {
                try {
                    callBack(result);
                } catch (e) {
                    console.log(e);
                }
            },
            error: function (x, y, z) {
                callBack(null, x);
            }
        });
    } catch (err) {
        console.log("Error in SendOTP : " + err);
        callBack(null, err);
    }
}

function HandleSyncResponse(response) {
    if(response.PrepareAggregate){
    $(".sync-refresh").removeClass("active");
    }
    try {
        isSyncing = false;
       // console.log(response);
        // if (response) {
        //     var result = UpdateMasterDataInLocalStorage(response);
        //     getMyStore().dispatch(StoreMasterData(result));
        // }

        console.log(response.LOGDATA);
        console.log(startDateOfSyncHandling);
        //console.log("SyncResponse");
        //console.log(new Date());

        // if (globalSyncCallBack) {
        //     globalSyncCallBack(null);

        // }

        console.log(GetDateDiff(startDateOfSyncHandling,new Date()));
        if (response) {
            UpdateLocalData(response, function (result) {

               

                
                //getMyStore().dispatch(StoreUserData(result));
                if (response.SkipCount == 0) {
                    var userResult = {};
                    userResult = result;
                    if (userResult.CFUser) {

                        try {
                            delete userResult.CFUser['IsRecordFrom'];
                            delete userResult.CFUser['Token'];
                        } catch (e) {
                            console.log(e);
                        }
                        userResult.CFUser.UId = parseInt(userResult.CFUser.UId);
                        localStorage.setItem("UserData", JSON.stringify(userResult.CFUser));
                      //  localStorage.setItem("LastPSSyncDataTime", userResult.CFUser);
                    }

                   // getMyStore().dispatch(StoreUserData(userResult));
                }
                var lasdtFromLocalStorage = localStorage.getItem("LastPSSyncDataTime");
                // if (response.Data.length >= 1000) {
                //     response.SkipCount += 1000;
                //     SocketSync(response.SkipCount, null, false, false, response.IsUpdate);
                // }
                //else if (lasdtFromLocalStorage && !response.IsUpdate)
                //{
                //    getMyStore().dispatch(StoreUserData(result));
                //    SocketSync(0, null,false,false, true);
                //}
               // else {
                    if (!response.LastSyncDataTime) {
                        var exception = new Object();
                        exception.Type = "OH MY GOD";
                        var userData = result.CFUser;
                        exception.Message = (userData ? userData.F1 : "") + " " + " LAST SYNC DATE TIME NULL AFTER SYNC ";
                        SaveExceptionToSentry(exception, "OH MY GOD", "", "");
                    }
                    //if (response.Data && response.Data.length > 0) {
                    localStorage.setItem("LastPSSyncDataTime", response.LastSyncDataTime || "");
                    //}
                    
                    //getMyStore().dispatch(StoreUserData(result));
                    if (globalSyncCallBack) {
                        globalSyncCallBack(result);

                    }

                    if (result.UserSettings && result.UserSettings.length) {
                        var valFromServer = $.grep(result.UserSettings, function (e) { return (e.Name && e.Name.toUpperCase().indexOf("SWVERSION") > -1) })[0];

                        if (window.swVersion && valFromServer) {

                            if (window.swVersion != valFromServer.Value) {
                                FlushServiceWorkerCache(window.swVersion, valFromServer.Value, function (res) {

                                    if (res) {
                                        //message, header, buttonvalue, isItError, callback
                                        showLockedBox("Benefit through innovative empowerment", "Pinnacle Updated", "Refresh for Pinnacle Latest", false, function () {

                                            //try {
                                            //    SocketSync(0, function (result, error) {
                                            //        try {

                                            //            if (error) {
                                            //                //showLockedBox(error.ErrorMsg, false);
                                            //            }
                                            //        } catch (e) {
                                            //            console.log("Error in OnDemandSync :" + err);
                                            //        }
                                            //    }, true, "ForceRefresh"); 
                                            //} catch (e) {

                                            //}
                                                
                                            window.location.href = "/d";
                                        });
                                    }
                                });
                            }
                        }
                        else {

                            //FlushServiceWorkerCache(function (res) {

                            //});
                        }
                    }
               // }
            });
        }
    } catch (err) {
        SaveCleintWaufli(err,"HandleSyncResponse Failed"," HandleSyncResponse ");
       
        SaveExceptionToSentry(err, "Client Handle Sync Error", "", JSON.stringify(err));
        console.log("Error in HandleSyncResponse :: " + err);
    }
}


function HandleMirracleSyncResponse(response) {
    try {
        isSyncing = false;
       

        console.log(response.LOGDATA);

        console.log("MirracleSyncResponse");
        console.log(new Date());
        if (response) {
            UpdateLocalData(response, function (result) {

                //getMyStore().dispatch(StoreUserData(result));
               
                var lasdtFromLocalStorage = localStorage.getItem("LastMirracleCFSyncDataTime");
                //if (response.Data.length >= 1000) {
                //    response.SkipCount += 1000;
                //    SocketSync(response.SkipCount, null, false, false, response.IsUpdate);
                //}
                //else if (lasdtFromLocalStorage && !response.IsUpdate)
                //{
                //    getMyStore().dispatch(StoreUserData(result));
                //    SocketSync(0, null,false,false, true);
                //}
              //  else {
                    console.log("LastMirracleCFSyncDataTime");
                    console.log(new Date());
                    if (!response.LastSyncDataTime) {
                        var exception = new Object();
                        exception.Type = "OH MY GOD";
                        var userData = result.CFUser;
                        exception.Message = (userData ? userData.F1 : "") + " " + " LAST SYNC DATE TIME NULL AFTER MIRRACLESYNC ";
                        SaveExceptionToSentry(exception, "OH MY GOD", "", "");
                    }
                    //if (response.Data && response.Data.length > 0) {
                    localStorage.setItem("LastMirracleCFSyncDataTime", response.LastSyncDataTime || "");
                    //}

                    getMyStore().dispatch(StoreUserData(result));
                    if (globalMirracleSyncCallBack) {
                        globalMirracleSyncCallBack(result);

                    }

                  
                //}
            });
        }
    } catch (err) {
        SaveExceptionToSentry(err, "Client Handle MirracleSync Error", "", JSON.stringify(err));
        console.log("Error in HandleMirracleSyncResponse :: " + err);
    }
}

function HandleGetActivitiesResponse(response) {
    try {
        if (response.Data && globalActivitiesCallBack) {
            globalActivitiesCallBack(response.Data, null, response.FData);
        }
    } catch (err) {
        console.log("Error in HandleGetActivitiesResponse :: " + err);
    }
}

function HandleMirracleResponse(response) {
    try {
        if (response.Data && globalMConfigCallBack) {
            globalMConfigCallBack(response.Data, null, response.FData);
        }
    } catch (err) {
        console.log("Error in HandleMirracleResponse :: " + err);
    }
}

function HandleMirracleReportResponse(response) {
    try {
        if (response.Data && globalMReportCallBack) {
            globalMReportCallBack(response.Data, null, response.FData);
        }
    } catch (err) {
        console.log("Error in HandleMirracleReportResponse :: " + err);
    }
}

function HandleDataCreationResponse(response) {
    try {
        if (response.Data && globalDataCreationCallBack) {
            globalDataCreationCallBack(response.Data,null,response);

            try {
                // calling sync after creation
                SocketSync(0, function (data) {});
            } catch (e) {
                SaveCleintWaufli(e,"HandleDataCreationResponse After Sync Failed","HandleDataCreationResponse");
            }
        }
    } catch (err) {
        SaveCleintWaufli(err,"HandleDataCreationResponse Failed","HandleDataCreationResponse");
    }
}

function HandleCustomDataResponse(response) {
    try {
        if (response.Data && globalCustomRoyaltyCallBack) {
            globalCustomRoyaltyCallBack(response.Data,null,response);

            try {
                // calling sync after creation
               
            } catch (e) {
                SaveCleintWaufli(e,"HandleCustomDataResponse After Sync Failed","HandleCustomDataResponse");
            }
        }
    } catch (err) {
        
        SaveCleintWaufli(err,"HandleCustomDataResponse Failed","HandleCustomDataResponse");
    }
}

function HandleUserCustomDataResponse(response) {
    try {
        if (response.Data && globalUserCustomDataCallBack) {
            globalUserCustomDataCallBack(response.Data,null,response);

            try {
                // calling sync after creation
               
            } catch (e) {
                SaveCleintWaufli(e,"HandleUserCustomDataResponse After Sync Failed","HandleUserCustomDataResponse");
            }
        }
    } catch (err) {
        
        SaveCleintWaufli(err,"HandleUserCustomDataResponse Failed","HandleUserCustomDataResponse");
    }
}

function HandleCustomLeadDataResponse(response) {
    try {
        if (response.Data && globalCustomLeadCallBack) {
            globalCustomLeadCallBack(response.Data,null,response);

            try {
                // calling sync after creation
               
            } catch (e) {
                //SaveCleintWaufli(e,"HandleCustomLeadDataResponse After Sync Failed","HandleCustomLeadDataResponse");
            }
        }
    } catch (err) {
        
        //SaveCleintWaufli(err,"HandleCustomLeadDataResponse Failed","HandleCustomLeadDataResponse");
    }
}

function HandleBatchDataUpdationResponse(response) {
    try {
        if (response.Data && globalDataBatchUpdateCallBack) {
            globalDataBatchUpdateCallBack(response.Data,null,response);

            try {
                 // calling sync after creation
                SocketSync(0, function (data) {                
                });
            } catch (e) {
                SaveCleintWaufli(e,"HandleBatchDataUpdationResponse After Sync Failed Failed","HandleBatchDataUpdationResponse");
            }
        }
    } catch (err) {
        SaveCleintWaufli(err,"HandleBatchDataUpdationResponse Failed","HandleBatchDataUpdationResponse");
    }
}

//DATABATCHUPDATION

function HandleGetAggreegatesResponse(response) {
    try {
        if (response.Data && globalAggreegatesCallBack) {
            globalAggreegatesCallBack(response.Data);
        }
    } catch (err) {
        console.log("Error in HandleGetAggreegatesResponse :: " + err);
    }
}

function HandleGetUsersResponse(response) {
    try {
        if (response.Data && globalUsersDaataCallBack) {
            globalUsersDaataCallBack(response);
        }
    } catch (err) {
        console.log("Error in HandleGetUsersResponse :: " + err);
    }
}

function HandleLeadsDataResponse(response) {
    try {
        if (response.Data && globalLeadsDataCallBack) {
            globalLeadsDataCallBack(response.Data);
        }
    } catch (err) {
        console.log("Error in HandleLeadsDataResponse :: " + err);
    }
}

function HandleProfileCardDataResponse(response) {
    try {
        if (response && globalProfileCardCallBack) {
            globalProfileCardCallBack(response);
        }
    } catch (err) {
        console.log("Error in HandleProfileCardDataResponse :: " + err);
    }
}

function HandleLeadHistoryDataResponse(response) {
    try {
        if (response && globalLeadHistoryCallBack) {
            globalLeadHistoryCallBack(response.Data);
        }
    } catch (err) {
        console.log("Error in HandleLeadHistoryDataResponse :: " + err);
    }
}

function HandleGroupsDataResponse(response) {
    try {
        if (response && globalGroupsDataCallBack) {
            globalGroupsDataCallBack(response.Data);
        }
    } catch (err) {
        console.log("Error in HandleGroupsDataResponse :: " + err);
    }
}

function HandleUserInfoDataResponse(response) {
    try {
        if (response && globalUserInfoCallBack) {
            localStorage.setItem("UserInfo", JSON.stringify(response.Data));
            getMyStore().dispatch(StoreUserInfoData(response.Data));
            globalUserInfoCallBack(response);
        }
    } catch (err) {
        console.log("Error in HandleUserInfoDataResponse :: " + err);
    }
}

function HandleUserCreationDataResponse(response) {
    try {
        if (response && globalUserCreationCallBack) {
            
            globalUserCreationCallBack(response.Data);
        }
    } catch (err) {
        console.log("Error in HandleUserCreationDataResponse :: " + err);
    }
}

function HandleSendOTPResponse(response) {
    try {
        if (response && globalSendOTPCallBack) {
            globalSendOTPCallBack(response);
        }
    } catch (err) {
        console.log("Error in HandleSendOTPResponse :: " + err);
    }
}

function HandleValidateOTPResponse(response) {
    try {
        if (response && globalValidateOTPCallBack) {
            globalValidateOTPCallBack(response);
        }
    } catch (err) {
        console.log("Error in HandleValidateOTPResponse :: " + err);
    }
}

function UpdateMasterDataInLocalStorage(result) {

    console.log("UpdateMasterDataInLocalStorage Function Called");
    try {

        var lResultString = localStorage.getItem("MasterData");
        var lResult = {};

        if (lResultString) {
            lResult = JSON.parse(lResultString);
        }

        if (!result) {
            result = {};
        }

        //#region ============ Master Data =============

        if (result.MasterData) {
            if (!lResult.MasterData) {
                lResult.MasterData = result.MasterData;
            }
            else {
                var eIds = $.map(result.MasterData, function (val, i) {
                    return val.Id ? val.Id : val.Id
                });

                if (eIds != null && eIds.length > 0) {
                    lResult.MasterData = $.grep(lResult.MasterData, function (e) { return !(eIds.indexOf(e.Id) > -1 || eIds.indexOf(e.Id) > -1) });
                    Array.prototype.push.apply(lResult.MasterData, result.MasterData);
                }
            }
        }

        if (result.Facilities) {
            if (!lResult.Facilities) {
                lResult.Facilities = result.Facilities;
            }
            else {
                var eIds = $.map(result.Facilities, function (val, i) {
                    return val.UId ? val.UId : val.UId
                });

                if (eIds != null && eIds.length > 0) {
                    lResult.Facilities = $.grep(lResult.Facilities, function (e) { return !(eIds.indexOf(e.UId) > -1 || eIds.indexOf(e.Id) > -1) });
                    Array.prototype.push.apply(lResult.Facilities, result.Facilities);
                }
            }
        }


        if (result.AllFacilities) {
            if (!lResult.AllFacilities) {
                lResult.AllFacilities = result.AllFacilities;
            }
            else {
                var eIds = $.map(result.AllFacilities, function (val, i) {
                    return val.UId ? val.UId : val.UId
                });

                if (eIds != null && eIds.length > 0) {
                    lResult.AllFacilities = $.grep(lResult.AllFacilities, function (e) { return !(eIds.indexOf(e.UId) > -1 || eIds.indexOf(e.Id) > -1) });
                    Array.prototype.push.apply(lResult.AllFacilities, result.AllFacilities);
                }
            }
        }

        lResult.AggregateData = result.AggregateData ? result.AggregateData : lResult.AggregateData;

        //#endregion ============ Master Data =============

        localStorage.setItem("MasterData", JSON.stringify(lResult));

        if (result.UserSettings) {
            localStorage.setItem("UserSettings", JSON.stringify(result.UserSettings));
        }

        return lResult;

    } catch (e) {
        console.log("Error in UpdateMasterDataInLocalStorage : " + e);
    }
}

export function mirracleMoreKey()
{
       var date = new Date();
       var key =  date.getDate()+"-"+date.getMonth()+"-"+date.getFullYear();
      return key;
}

export function getMirracleSearchKey() {
    return localStorage.getItem("mirraclessearchkey");
}
export function storeMirracleSearchKey(key) {
    localStorage.setItem("mirraclessearchkey", key);
}


////function to update all LS datetime in localstorage
function UpdateTablesSyncDateTime(result) {
    
    try {
console.log('UpdateTablesSyncDateTime Called');
        Object.keys(result).forEach((key, index) => {
            if (result[key].length > 0) {
                console.log(key);
                //console.log(TableLastSyncDateTime[key]);
               // console.log(result[key]);
                if(TableLastSyncDateTime[key]){
                    SetLocalStorage(TableLastSyncDateTime[key], GetHighestCDTorUDT(result[key],key));
                }
            }

        });
        console.log('UpdateTablesSyncDateTime END');
    }
    catch (e) {
        console.log("error in Update Local Data", e);
        SaveExceptionToSentry(e, "Client UpdateTablesSyncDateTime", "", JSON.stringify(e));
    }
}


function GetTablesSyncDateTime() {

    try {

        var obj = {}
        Object.keys(TableLastSyncDateTime).forEach((key, index) => {

            var value = GetLocalStorageByKey(TableLastSyncDateTime[key]);
            if (value) {
                obj[key] = value;
            }

        });

        return obj;

    }
    catch (e) {
        console.log(e);
    }

}


/**Function to get Highest CDT or UDT based on Data passed */
function GetHighestCDTorUDT(data,type) {
    try {
      //  console.log("GETHIGHEST CDT CALLED FOR " + type);
        let combinedDates = data.reduce((acc, obj) => { acc.push(obj.CDT); acc.push(obj.UDT); return acc; }, []);
        
        //console.log("GETHIGHEST COMBINED FOR " + type);
        
       // let dateToReturn =  new Date(Math.max.apply(null, combinedDates.map(function (e) {return new Date(e);})));
      
       let dateToReturn = combinedDates.map(function (e) {return new Date(e);}).sort(function (a, b) {return a > b ? -1 : 1 })[0];
      
       dateToReturn.setMinutes(dateToReturn.getMinutes() - 30);
       //console.log(type + " = "+ dateToReturn);
       //console.log("add 30 min - " + dateToReturn);
       // console.log("GETHIGHEST MAX FOR " + type);

        return dateToReturn ? PSdateFormate(dateToReturn) : null;
    }
    catch (e) {
        console.log(e);
    }
}


function GetLocalStorageByKey(key){
    try{

        
         var value =   localStorage.getItem(key);
        
        return value ? JSON.parse(value) : null;

    }
    catch(e){
        console.log(e);
    }
}

function SetLocalStorage(key,value){
    try{

        if(value){
            localStorage.setItem(key, JSON.stringify(value));
        }
        

    }
    catch(e){
        console.log(e);
    }
}







/*
THIS METHOD IS MENT TO MERGE THE INCOMING DATA
*/
function MergeData(existingData, newData) {
    try {
        //IF NO ASSESSMENT EXITS ASSIGN DIRECTLY
        if (!existingData) {

            existingData = newData;
        }
        else {
            //REMOVE INCOMINGS
            var eIds = $.map(newData, function (val, i) {
                return val.Id + ""
            });

            if (eIds != null && eIds.length > 0) {

                existingData = existingData.filter(function(e){ return !(eIds.indexOf(e.Id + "") > -1) });// $.grep(existingData, function (e) { return !(eIds.indexOf(e.Id + "") > -1) });
                Array.prototype.push.apply(existingData, newData);
            }
        }

        return existingData;
    }
    catch (e) {

        SaveCleintWaufli(e,"MergeData Failed"," MergeData ");
       
        console.log("Error in Merging Local Data", e);
    }
}


// var prepAggregatesAlreadyInPipe = false;
// function UpdateLocalData(result, callback) {

//     try {

//         console.log("UpdateLocalData called");
//         ///GET DATA FROM GLOBAL VARIABLE
//         var indexDBResult = window.globalCacheData ? window.globalCacheData : null;
//        // console.log("Indexdb Result",indexDBResult);

//         //SOURABH :: CHECK LOCAL DATA and LS KEY, IF LS KEY AND NO CACHE DATA FULL SYNC
//         //REMOVING LAST SYNC DATETIME and ALL TABLE KEYS FOR INITIATING FULL SYNC
//         var lsSyncData = localStorage.getItem("LastPSSyncDataTime");
//         if (!indexDBResult && lsSyncData) {
//             localStorage.removeItem("LastPSSyncDataTime");

//             //REMOVE ALL LOCAL TABLE KEYS
//             Object.keys(TableLastSyncDateTime).forEach((key, index) => {
//                 localStorage.removeItem(TableLastSyncDateTime[key]);
//             });

//             ///CALL SYNC
//             SocketSync(0, function (data) {
//                 // console.log(data);
//                 console.log("After SYNC From Local Data RESET");
//                 console.log(new Date());
//             });
//             return;
//         }

//         //UPDATE TO DB FLAG
//         var isDBNeedUpdated = false;
//         var prepAggregate = false;
//         if (indexDBResult == null || indexDBResult == "" || typeof (indexDBResult) == "undefined")
//             indexDBResult = {};
        
       
//         // #region User Data From Cache
//         if (result.CurUser) {
//             //checking with mobile number.
//             if (result.CurUser.Handle) {
//                 isDBNeedUpdated = true;
//                 indexDBResult.CFUser = result.CurUser;
//             }
//         }

//         if(result.PrepareAggregate){
//             prepAggregate = true;
//         }
//         console.log("CF USER  Assiggned");
//         // #endregion

//         // #region master data handling


//         if (result.UserSettings) {
//             indexDBResult.UserSettings = result.UserSettings;

          
//             localStorage.setItem("UserSettings", JSON.stringify(result.UserSettings));
            
//             isDBNeedUpdated = true;
//         }

//         console.log("UserSettings  Assiggned");
//         if (result.Types) {
//             indexDBResult.Types = result.Types;
//             isDBNeedUpdated = true;
//         }

//         if (result.Centers && result.Centers.length > 0) {
//             indexDBResult.Centers = result.Centers;
//         }
//         console.log("Centers  Assiggned");
//         //

       
//         console.log("Mirracles  Assiggned");
//         // #endregion Offers Data Handling



//         var mergeWorker = new Worker("/workers/worker.js");
//         mergeWorker.onmessage = ev => {
         

//          var method = ev.data &&  ev.data.Method;

//           if(method == "DataMerge")
//           {
//             indexDBResult = ev.data.Data;

            
//         if (result.LastSyncDataTime) {
//             indexDBResult.LastSyncDataTime = result.LastSyncDataTime;
//             localStorage.setItem("LastPSSyncDataTime", result.LastSyncDataTime);
//         }
//         console.log("Mirracles  Assiggned");
       
//         let isDataUpdatedInCache = false;
//         if (isDBNeedUpdated) {

//             if (window.globalCacheInstance) {
//                 window.globalCacheInstance.put("/PSUserData", new Response(JSON.stringify(indexDBResult)));
//                 isDataUpdatedInCache = true;
//             }
//             else {

//                 caches.open(apiCacheName).then(cache => {
//                     cache.match("/PSUserData").then(async response => {

//                         cache.put("/PSUserData", new Response(JSON.stringify(indexDBResult)));
//                         UpdateTablesSyncDateTime(result);
//                     }).catch(error => {
//                         SaveCleintWaufli(error, "Error in Putting Local Data To Cache", " UpdateLocalData ");
//                     });
//                 });

//             }
            
//         }
//         console.log("Updated  Assiggned");

       

//         console.log("Updated in cache store");

     
         
//         // if (isDataUpdatedInCache && !prepAggregatesAlreadyInPipe) {
//         //     prepAggregatesAlreadyInPipe = true;
//         //     setTimeout(function () {
//                 console.log("PrepAggregates called");
//                   // Prep Aggregates
//         var roles = localStorage.getItem("UserRoles");
//         //var allowedRoles = ["Owner","Boss", "Therapist", "Receptionist","Senior", "ChiefTherapist", "FacilityOwner", "CCC","NCC", "HarvestAdmin", "Manager", "Harvest"];
//         var allowedRoles = ["Owner","FacilityOwner","Investor","Manager","Harvest","HarvestAdmin","BDC","NCC","Receptionist","ChiefTherapist","Senior","Therapist","CCC","ClarityContributer","Boss","Lead"];
//         if(roles == "undefined"){
//             roles = JSON.stringify("Lead");
//         }
//         var rolesParsed = roles ? JSON.parse(roles) : [];
//         var roles = allowedRoles.filter(f=> rolesParsed.indexOf(f) > -1);
//         var role = roles && roles[0] ? roles[0] : 'Boss';
//         var thisInstance = {IsUserTherapist : role && role.toLowerCase() == "therapist" ? true :false};
       
// if(indexDBResult && indexDBResult.CFUser)
// {


//                     var worker = new Worker("/workers/worker.js");
//                     worker.onmessage = ev => {
//                       console.log("got data back from worker");
                     
    
//                      var method = ev.data &&  ev.data.Method;
    
//                       if(method == "Aggregate")
//                       {
//                      var aggregate =  ev.data.Data;
//                      aggregate.CDT = new Date();
//                     // aggregate.UDT = new Date();
//                     // localStorage.setItem("USERMASTERAGGREGATES",JSON.stringify(aggregate));
//                    //  selfObj.setState({ currentAggregate: agg,IsDataLoaded:true, CurrentAggregates: [agg], CentersDataInclude: centersDataInclude });
    
    
//                    //var aggregate = PrepAggregates(role, indexDBResult, "", "", thisInstance);
    
//                    var individualAggregates = [];// role = "Owner" || role == "Manager" ?  PrepIndividualCenterAggregates(thisInstance,indexDBResult) : [];
           
//                    console.log("aggregate data prep after sync");
//                    //console.log(aggregate);
           
//                    //setTimeout(function(){
//                    // Process User Data
//                       ProcessUserData(indexDBResult,getMyStore(),aggregate,individualAggregates);
//                       }
//                     }

//                     var dWorker = {"Role":role,$this:thisInstance,Type:"MONTHLYAGGREGATES","FacilityId":null,  "Data":indexDBResult,"Method":"Aggregate"};
// //                 //    console.log(dWorker);
//                    worker.postMessage(dWorker);


// }
// else{


//                 prepAggregatesAlreadyInPipe = false;

//                 if(prepAggregate){
//                      console.log("NEWSYNC - PREP AGGREGATE TRUE");
//                 }
//                 else{
//                     console.log("NEWSYNC - PREP AGGREGATE FALSE");
//                 }
//                 var aggregate = PrepAggregates(role, indexDBResult, "", "", thisInstance);

//                 var individualAggregates = [];// role = "Owner" || role == "Manager" ?  PrepIndividualCenterAggregates(thisInstance,indexDBResult) : [];
        
//                 console.log("aggregate data prep after sync");
//                 //console.log(aggregate);
        
//                 //setTimeout(function(){
//                 // Process User Data
//                    ProcessUserData(indexDBResult,getMyStore(),aggregate,individualAggregates);
//                    console.log("Process Data Completed");
//                 //},100);
        
//             //}, 10000);
//        // }
//             }

//        if (isDataUpdatedInCache) {
//         UpdateTablesSyncDateTime(result);
//         console.log("UpdateTablesSyncDateTime Done");
//     }
      
//         if (callback) {
//             callback(indexDBResult);
//         }

//           }
//         }

//         if(indexDBResult && result)
//         {
//         var mergeData = {IndexDB:indexDBResult,Result:result, "Method":"DataMerge"};
// //                 //    console.log(dWorker);
//         mergeWorker.postMessage(mergeData);
//         }




//     }
//     catch (e) {
//        // SaveCleintWaufli(e,"UpdateLocalData Failed"," UpdateLocalData ");
//         console.log("error in Update Local Data", e);
//     }

// } 

var prepAggregatesAlreadyInPipe = false;
function UpdateLocalData(result, callback) {

    try {
        //sleep(1000).then(function(){
            
            console.log("UpdateLocalData called");
            ///GET DATA FROM GLOBAL VARIABLE
            var indexDBResult = window.globalCacheData ? window.globalCacheData : null;
           // console.log("Indexdb Result",indexDBResult);
    
             //SOURABH :: CHECK LOCAL DATA and LS KEY, IF LS KEY AND NO CACHE DATA FULL SYNC
            //REMOVING LAST SYNC DATETIME and ALL TABLE KEYS FOR INITIATING FULL SYNC
            var lsSyncData = localStorage.getItem("LastPSSyncDataTime");
            ;
            // if (!indexDBResult && lsSyncData) {
    
            // }
        
            if (!indexDBResult && lsSyncData) {
                console.log("indexdb indexdbresult");
                localStorage.removeItem("LastPSSyncDataTime");
    
                //REMOVE ALL LOCAL TABLE KEYS
                Object.keys(TableLastSyncDateTime).forEach((key, index) => {
                    localStorage.removeItem(TableLastSyncDateTime[key]);
                });
    
                ///CALL SYNC
                SocketSync(0, function (data) {
                    // console.log(data);
                    console.log("After SYNC From Local Data RESET");
                    console.log(new Date());
                });
                return;
            }
    
            //UPDATE TO DB FLAG
            var isDBNeedUpdated = false;
            var prepAggregate = false;
            if (indexDBResult == null || indexDBResult == "" || typeof (indexDBResult) == "undefined")
                indexDBResult = {};
            
           
            // #region User Data From Cache
            if (result.CurUser) {
                //checking with mobile number.
                if (result.CurUser.Handle) {
                    isDBNeedUpdated = true;
                    indexDBResult.CFUser = result.CurUser;
                }
            }
    
            if(result.PrepareAggregate){
                prepAggregate = true;
            }
            console.log("CF USER  Assiggned");
            // #endregion
    
            // #region master data handling
    
    
            if (result.UserSettings) {
                indexDBResult.UserSettings = result.UserSettings;
    
              
                localStorage.setItem("UserSettings", JSON.stringify(result.UserSettings));
                
                isDBNeedUpdated = true;
            }
    
            console.log("UserSettings  Assiggned");
            if (result.Types) {
                indexDBResult.Types = result.Types;
                isDBNeedUpdated = true;
            }
    
            if (result.Centers && result.Centers.length > 0) {
                indexDBResult.Centers = result.Centers;
            }
            console.log("Centers  Assiggned");
            //
    
            if (result.Aggregates && result.Aggregates.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.Aggregates = MergeData(indexDBResult.Aggregates, result.Aggregates)
            }
    
            console.log("Aggregates  Assiggned");
            // #endregion
    
            // #region Assessment Data Hanling    
            if (result.Assessments && result.Assessments.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.Assessments = MergeData(indexDBResult.Assessments, result.Assessments);
            }
    
            console.log("Assessments  Assiggned");
            // #endregion Asessment Data Handling
    
            // #region Attendance Data Hanling    
            if (result.Attendance && result.Attendance.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.Attendance = MergeData(indexDBResult.Attendance, result.Attendance);
            }
            console.log("Attendance  Assiggned");
    
             // #region Attendance Data Hanling    
             if (result.TherapeuticAI && result.TherapeuticAI.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.TherapeuticAI = MergeData(indexDBResult.TherapeuticAI, result.TherapeuticAI);
            }
            console.log("TherapeuticAI  Assiggned");
    
              // #region Attendance Data Hanling    
              if (result.DemoGraphicAI && result.DemoGraphicAI.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.DemoGraphicAI = MergeData(indexDBResult.DemoGraphicAI, result.DemoGraphicAI);
            }
            console.log("DemoGraphicAI  Assiggned");
            //TherapeuticAI
            // #region DemoGraphicAINOtes Data Hanling    
            if (result.DemoGraphicAINotes && result.DemoGraphicAINotes.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.DemoGraphicAINotes = MergeData(indexDBResult.DemoGraphicAINotes, result.DemoGraphicAINotes);
            }
            console.log("DemoGraphicAINotes  Assiggned");
            //TherapeuticAI
            //DemographicAI
            // #endregion Attendance Data Handling
    
    
             // #region PinnacleClarity Data Hanling    
             if (result.PinnacleClarity && result.PinnacleClarity.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.PinnacleClarity = MergeData(indexDBResult.PinnacleClarity, result.PinnacleClarity);
            }
            console.log("PinnacleClarity  Assiggned");
            // #endregion PinnacleClarity Data Handling
    
        
    
            // #region Biometric Data Hanling    
            if (result.Biometric && result.Biometric.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.Biometric = MergeData(indexDBResult.Biometric, result.Biometric);
            }
            console.log("Biometric  Assiggned");
            // #endregion Biometric Data Handling
    
    
            // #region Goals Data Hanling    
            if (result.Goals && result.Goals.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.Goals = MergeData(indexDBResult.Goals, result.Goals)
            }
            console.log("Goals  Assiggned");
            // #endregion Goals Data Handling


            // #region MyDevices Data Hanling    
            if (result.MyDevices && result.MyDevices.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.MyDevices = MergeData(indexDBResult.MyDevices, result.MyDevices)
            }
            console.log("MyDevices  Assiggned");
            // #endregion MyDevices Data Handling
    
            // #region Responses Data Hanling    
            if (result.Responses && result.Responses.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.Responses = MergeData(indexDBResult.Responses, result.Responses)
            }
            console.log("Responses  Assiggned");
            // #endregion Responses Data Handling
    
            // #region CenterNotes Data Hanling    
            if (result.CenterNotes && result.CenterNotes.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.CenterNotes = MergeData(indexDBResult.CenterNotes, result.CenterNotes)
            }
            console.log("CenterNotes  Assiggned");
            // #endregion CenterNotes Data Handling
    
    
            // #region Fee Data Hanling    
            if (result.Fee && result.Fee.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.Fee = MergeData(indexDBResult.Fee, result.Fee)
            }
            console.log("Fee  Assiggned");
            // #endregion Goals Data Handling
    
            // #region Feedback Data Hanling    
            if (result.Feedback && result.Feedback.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.Feedback = MergeData(indexDBResult.Feedback, result.Feedback)
            }
            console.log("Feedback  Assiggned");
            // #endregion Goals Data Handling
    
            // #region Leads Data Hanling    
            if (result.Leads && result.Leads.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.Leads = MergeData(indexDBResult.Leads, result.Leads)
            }
            console.log("Leads  Assiggned");
            // #endregion Leads Data Handling
    
            // #region FeeReceipt Data Hanling    
            if (result.FeeReceipt && result.FeeReceipt.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.FeeReceipt = MergeData(indexDBResult.FeeReceipt, result.FeeReceipt)
            }
            console.log("FeeReciept  Assiggned");
            // #endregion Goals Data Handling
    
            // #region People Data Hanling    
            if (result.People && result.People.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.People = MergeData(indexDBResult.People, result.People)
            }
            console.log("People  Assiggned");
            // #endregion People Data Handling
    
            // #region People Data Hanling    
            if (result.FacilityPeople && result.FacilityPeople.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.FacilityPeople = MergeData(indexDBResult.FacilityPeople, result.FacilityPeople)
            }
            console.log("FacilityPeople  Assiggned");
            // #endregion People Data Handling
    
            // #region PeopleNotes Data Hanling    
            if (result.PeopleNotes && result.PeopleNotes.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.PeopleNotes = MergeData(indexDBResult.PeopleNotes, result.PeopleNotes)
            }
            console.log("PeopleNotes  Assiggned");
            // #endregion People Data Handling
    
            // #region People Data Hanling    
            if (result.ServiceRequests && result.ServiceRequests.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.ServiceRequests = MergeData(indexDBResult.ServiceRequests, result.ServiceRequests)
            }
            console.log("ServiceRequests  Assiggned");
            // #endregion People Data Handling
    
            // #region ServiceRequestsAnalytics Data Hanling    
            if (result.ServiceRequestsAnalytics && result.ServiceRequestsAnalytics.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.ServiceRequestsAnalytics = MergeData(indexDBResult.ServiceRequestsAnalytics, result.ServiceRequestsAnalytics)
            }
            console.log("ServiceRequestsAnalytics  Assiggned");
            // #endregion People Data Handling
    
            // #region MirracleTypes Data Hanling    
            if (result.MirracleTypes && result.MirracleTypes.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.MirracleTypes = MergeData(indexDBResult.MirracleTypes, result.MirracleTypes)
            }
            // #endregion People Data Handling
    
            // #region Guru Data Hanling    
            if (result.Guru && result.Guru.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.Guru = MergeData(indexDBResult.Guru, result.Guru)
            }


            // #region HelloPinnacle Data Hanling    
            if (result.HelloPinnacle && result.HelloPinnacle.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.HelloPinnacle = MergeData(indexDBResult.HelloPinnacle, result.HelloPinnacle)
            }
            console.log("Guru  Assiggned");
            // #endregion Guru Data Handling
    
        
    
            // #region Therapies Data Hanling    
            if (result.Therapies && result.Therapies.length > 0) {
    
    
                var deletedServices = result.Therapies.filter(f=> f.RS == "DELETEACTIVITY");
                deletedServices = deletedServices || [];
                try
                {
                                
                for(var i=0;i<deletedServices.length;i++)
                {
                    localStorage.removeItem(deletedServices[i].Id+"-goal");
                }
                }
                catch{
    
                }
                isDBNeedUpdated = true;
                indexDBResult.Therapies = MergeData(indexDBResult.Therapies, result.Therapies)
            }
    
            // #endregion Therapies Data Handling
    
            // #region Offers Data Hanling    
            if (result.Offers && result.Offers.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.Offers = MergeData(indexDBResult.Offers, result.Offers)
            }
            console.log("Offers  Assiggned");
    
            // #endregion Offers Data Handling
    
            // #region FacilityTherapies Data Hanling    
            if (result.FacilityTherapies && result.FacilityTherapies.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.FacilityTherapies = MergeData(indexDBResult.FacilityTherapies, result.FacilityTherapies)
            }
            console.log("FacilityTherapies  Assiggned");
            // #endregion Offers Data Handling
    
            // #region Therapies Data Hanling    
            if (result.KidStates && result.KidStates.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.KidStates = MergeData(indexDBResult.KidStates, result.KidStates)
            }
    
            // #region Mirracles Data Hanling    
            if (result.Mirracles && result.Mirracles.length > 0) {
                isDBNeedUpdated = true;
                indexDBResult.Mirracles = MergeData(indexDBResult.Mirracles, result.Mirracles)
            }
            console.log("Mirracles  Assiggned");
            // #endregion Offers Data Handling
    
            if (result.LastSyncDataTime) {
                indexDBResult.LastSyncDataTime = result.LastSyncDataTime;
                localStorage.setItem("LastPSSyncDataTime", result.LastSyncDataTime);
            }
            console.log("Mirracles  Assiggned");

            var rolesFromLS = localStorage.getItem("UserRoles");

            if(rolesFromLS)
            {
                var date=new Date()
                var rolesAfterParsed = JSON.parse(rolesFromLS);
                if (rolesAfterParsed && rolesAfterParsed.indexOf("BDC") > -1 || rolesAfterParsed.indexOf("NCC") > -1) {

                    var oldDate = date.setMonth(date.getMonth()-3)
                    indexDBResult.Fee = indexDBResult.Fee && indexDBResult.Fee.length > 0 ? indexDBResult.Fee.filter(e =>  new Date (oldDate) <= new Date(e.FDT)):[];
                    var mappedFeeData = indexDBResult.Fee && indexDBResult.Fee.length > 0 ?indexDBResult.Fee.map(e => e.Id):[]
                    indexDBResult.FeeReceipt = indexDBResult.FeeReceipt && indexDBResult.FeeReceipt .length > 0 ? indexDBResult.FeeReceipt.filter(e => mappedFeeData && mappedFeeData.indexOf(e.FeeId) > -1):[];

                }

            }

           
            let isDataUpdatedInCache = false;
            if (isDBNeedUpdated) {
                window.globalCacheData = indexDBResult;
                if (window.globalCacheInstance) {
                    window.globalCacheInstance.put("/PSUserData", new Response(JSON.stringify(indexDBResult)));
                    isDataUpdatedInCache = true;
                }
                else {
    
                    caches.open(apiCacheName).then(cache => {
                        cache.match("/PSUserData").then(async response => {
    
                            cache.put("/PSUserData", new Response(JSON.stringify(indexDBResult)));
                            UpdateTablesSyncDateTime(result);
                        }).catch(error => {
                            SaveCleintWaufli(error, "Error in Putting Local Data To Cache", " UpdateLocalData ");
                        });
                    });
    
                }
                
            }
            console.log("Updated  Assiggned");
    
           
    
            console.log("Updated in cache store");
    
         
             
            // if (isDataUpdatedInCache && !prepAggregatesAlreadyInPipe) {
            //     prepAggregatesAlreadyInPipe = true;
            //     setTimeout(function () {
                    console.log("PrepAggregates called");
                      // Prep Aggregates
            var roles = localStorage.getItem("UserRoles");
            //var allowedRoles = ["Owner","Boss", "Therapist", "Receptionist","Senior", "ChiefTherapist", "FacilityOwner", "CCC","NCC", "HarvestAdmin", "Manager", "Harvest"];
            var allowedRoles = ["Owner","FacilityOwner","Investor","Manager","Harvest","HarvestAdmin","BDC","NCC","Receptionist","ChiefTherapist","Senior","Therapist","CCC","ClarityContributer","Boss","Lead"];
            if(roles == "undefined"){
                roles = JSON.stringify("Lead");
            }
            var rolesParsed = roles ? JSON.parse(roles) : [];
            var roles = allowedRoles.filter(f=> rolesParsed.indexOf(f) > -1);
            var role = roles && roles[0] ? roles[0] : 'Boss';
            var thisInstance = {IsUserTherapist : role && role.toLowerCase() == "therapist" ? true :false};
           
    
    
    
                    prepAggregatesAlreadyInPipe = false;
    
                    if(prepAggregate){
                         console.log("NEWSYNC - PREP AGGREGATE TRUE");
                    }
                    else{
                        console.log("NEWSYNC - PREP AGGREGATE FALSE");
                    }
    
                
    
    
                    var worker = new Worker("/workers/worker.js?v=0.000005");
                    worker.onmessage = ev => {
                      console.log("got data back from worker");
                     
    
                     var method = ev.data &&  ev.data.Method;
    
                      if(method == "Aggregate")
                      {
                     var aggregate =  ev.data.Data;
                     aggregate.CDT = new Date();
                     aggregate.UDT = new Date();
                    // localStorage.setItem("USERMASTERAGGREGATES",JSON.stringify(aggregate));
                   //  selfObj.setState({ currentAggregate: agg,IsDataLoaded:true, CurrentAggregates: [agg], CentersDataInclude: centersDataInclude });
    
    
                   //var aggregate = PrepAggregates(role, indexDBResult, "", "", thisInstance);
    
                   var individualAggregates = [];// role = "Owner" || role == "Manager" ?  PrepIndividualCenterAggregates(thisInstance,indexDBResult) : [];
           
                   console.log("aggregate data prep after sync");
                   //console.log(aggregate);
           
                   //setTimeout(function(){
                   // Process User Data
                      ProcessUserData(indexDBResult,getMyStore(),aggregate,individualAggregates);
                      console.log("Process Data Completed");
    
                      if (isDataUpdatedInCache) {
                        UpdateTablesSyncDateTime(result);
                        console.log("UpdateTablesSyncDateTime Done");
                    }
                      
                        if (callback) {
                            callback(indexDBResult);
                        }
                   //},100);
    
                     // console.log(ev);
                      }
                    }
    
    
                    //role, indexDBResult, "", "", thisInstance
    
    
                        var dWorker = {"Role":role,$this:thisInstance,Type:"MONTHLYAGGREGATES","FacilityId":null,  "Data":indexDBResult,"Method":"Aggregate",LocalStorage:GetLocalStorageDetailsFor()};
                       console.log(dWorker);
                        worker.postMessage(dWorker);
                     
    
     //   });
       
              
                    
            //}, 10000);
       // }

       
       

    }
    catch (e) {
       // SaveCleintWaufli(e,"UpdateLocalData Failed"," UpdateLocalData ");
        console.log("error in Update Local Data", e);
    }

} 



function sleep(msec) {
    return new Promise(resolve => setTimeout(resolve, msec));
}


function UpdateUserDataInCacheDB(result, callback) {
    console.log("Update Function Called");
    try {
        caches.open(apiCacheName).then(cache => {
            cache.match("/PSUserData").then(async response => {
                try {
                    var indexDBResult = response ? await response.json() : null;
                    var isDBNeedUpdated = false;
                    if (indexDBResult == null || indexDBResult == "" || typeof (indexDBResult) == "undefined")
                        indexDBResult = {};

                  

                    // #region ============ FInfo Data =============

                    if (result.FInfo) {
                        if (!indexDBResult.FInfo) {
                            isDBNeedUpdated = true;
                            indexDBResult.FInfo = result.FInfo;
                        }
                        else {
                            var eIds = $.map(result.FInfo, function (val, i) {
                                return val.UId ? val.UId : val.Id
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.FInfo = $.grep(indexDBResult.FInfo, function (e) { return !(eIds.indexOf(e.Id) > -1 || eIds.indexOf(e.UId) > -1) });
                                Array.prototype.push.apply(indexDBResult.FInfo, result.FInfo);
                            }
                        }
                    }

                    // #endregion ============ FInfo Data =============


                    // #region ============ Facility Data =============

                    if (result.FData) {

                        var invoices = $.grep(result.FData, function (e) { return e.AT == "INVOICE" }).map(function (e) { return parseInt(e.UId) });
                        var pIds = $.grep(result.FData, function (e) { return e.AT == "PAYMENT" }).map(function (e) { return parseInt(e.UId) });
                        if (indexDBResult.FData) {
                            var payments = $.grep(indexDBResult.FData, function (e) { return e.AT == "PAYMENT" && pIds.indexOf(parseInt(e.UId)) == -1 && invoices.indexOf(e.RDUId[0]) > -1 });
                            if (payments) {
                                Array.prototype.push.apply(result.FData, payments);
                            }

                            var serviceRequestAnalytics = $.grep(result.FData, function (e) { return e.AT == "SERVICEREQUESTANALYTIC" });
                            if (serviceRequestAnalytics && result.FData.length == 1) {
                                Array.prototype.push.apply(result.FData, indexDBResult.FData);
                            }
                        }

                        //if (!indexDBResult.FData) {
                        isDBNeedUpdated = true;
                        indexDBResult.FData = result.FData;
                        //}
                        //else {
                        //    var eIds = $.map(result.FData, function (val, i) {
                        //        return val.UId ? val.UId : val.Id
                        //    });

                        //    if (eIds != null && eIds.length > 0) {
                        //        isDBNeedUpdated = true;
                        //        indexDBResult.FData = $.grep(indexDBResult.FData, function (e) { return !(eIds.indexOf(e.Id) > -1 || eIds.indexOf(e.UId) > -1) });
                        //        Array.prototype.push.apply(indexDBResult.FData, result.FData);
                        //    }
                        //}
                    }

                    // #endregion ============ Facility Data =============

                    // #region ============ User Data =============

                    if (result.CurUser) {
                        //checking with mobile number.
                        if (result.CurUser.Handle) {
                            isDBNeedUpdated = true;
                            indexDBResult.CFUser = result.CurUser;
                        }
                    }

                    // #endregion ============ User Data =============

                    // #region ============ User Data =============

                    if (result.UserSettings) {
                        indexDBResult.UserSettings = result.UserSettings;
                        isDBNeedUpdated = true;
                    }
                    if (result.Types) {
                        indexDBResult.Types = result.Types;
                        isDBNeedUpdated = true;
                    }


                    //indexDBResult.Mirracles = null;

                    if (result.Mirracles && result.Mirracles.length > 0) {
                        indexDBResult.Mirracles = result.Mirracles;
                        isDBNeedUpdated = true;
                    }

                    if (result.MirraclesMore && result.MirraclesMore.length > 0) {
                        var key = mirracleMoreKey();
                        var data = indexDBResult.MirraclesMore ? indexDBResult.MirraclesMore[key] : [];
                        var alreadyThere = result.MirraclesMore.map(m => m.UId);

                        data = data ? data : [];
                        data = data.filter(function (e) { return alreadyThere.indexOf(e.UId) == -1 });
                        data.push(...result.MirraclesMore);
                        var resultantMirracles = {};
                        resultantMirracles[key] = data;
                        indexDBResult.MirraclesMore = resultantMirracles;

                        localStorage.setItem("CurrentMirraclesDataTime", key);
                        isDBNeedUpdated = true;
                    }

                    if (result.MirraclesSearchMore && result.MirraclesSearchMore.length > 0) {
                        var key = mirracleMoreKey();
                        var lResultString = getMirracleSearchKey();// localStorage.getItem("mirraclessearchkey");
                        var data = indexDBResult.MirraclesSearchMore ? indexDBResult.MirraclesSearchMore[key] : {};
                        data = data ? data : {};
                        if (Array.isArray(data)) {
                            data = {};
                        }
                        var alreadyThere = result.MirraclesSearchMore.map(m => m.UId);
                        data[lResultString] = data[lResultString] ? data[lResultString] : [];

                        //data = data ? data : [];
                        data[lResultString] = data[lResultString].filter(function (e) { return alreadyThere.indexOf(e.UId) == -1 });
                        data[lResultString].push(...result.MirraclesSearchMore);
                        var resultantMirracles = {};
                        resultantMirracles[key] = data;
                        //indexDBResult.MirraclesSearchMore = {};
                        indexDBResult.MirraclesSearchMore = resultantMirracles;

                        localStorage.setItem("CurrentMirraclesDataTime", key);
                        isDBNeedUpdated = true;
                    }


                    // #endregion ============ User Data =============


                    // #region ============ User Data =============

                    var ServerData = {}
                    
                    ServerData.Assessments = result.Assessments;
                    ServerData.Attendance = result.Attendance;
                    ServerData.Goal = result.Goal;
                    ServerData.CenterNotes = result.CenterNotes;
                    ServerData.Fee = result.Fee;
                    ServerData.People = result.People;
                    ServerData.PeopleNotes = result.PeopleNotes;
                    ServerData.ServiceRequests = result.ServiceRequests;
                    ServerData.Therapies = result.Therapies;
                    ServerData.Offers = result.Offers;
                    ServerData.FeeReceipts = result.FeeReceipt;
                    ServerData.Aggregates = result.Aggregates;


                    if (result.Centers && result.Centers.length > 0) {
                        indexDBResult.Centers = result.Centers;
                    }

                    if (result.Aggregates && result.Aggregates.length > 0) {
                        indexDBResult.Aggregates = result.Aggregates;
                    }


                    if (result.Assessments && result.Assessments.length > 0) {

                        if (!indexDBResult.Assessments) {
                            isDBNeedUpdated = true;
                            indexDBResult.Assessments = result.Assessments;
                        }
                        else {
                          //  Array.prototype.push.apply(indexDBResult.Assessments, result.Assessments);

                            let alreadyExisitngServices = $.grep(result.Assessments, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.Assessments, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.Assessments = $.grep(indexDBResult.Assessments, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.Assessments, result.Assessments);
                            }
                        }
                    }


                    if (result.Attendance && result.Attendance.length > 0) {
                        if (!indexDBResult.Attendance) {
                            isDBNeedUpdated = true;
                            indexDBResult.Attendance = result.Attendance;
                        }
                        else {
                            //Array.prototype.push.apply(indexDBResult.Attendance, result.Attendance);

                            let alreadyExisitngServices = $.grep(result.Attendance, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.Attendance, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.Attendance = $.grep(indexDBResult.Attendance, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.Attendance, result.Attendance);



                            }
                        }
                    }

                    if (result.Biometric && result.Biometric.length > 0) {
                        if (!indexDBResult.Biometric) {
                            isDBNeedUpdated = true;
                            indexDBResult.Biometric = result.Biometric;
                        }
                        else {

                            // indexDBResult.Goals = result.Goals;
                            // Array.prototype.push.apply(indexDBResult.Goals, result.Goals);
                            let alreadyExisitngServices = $.grep(result.Biometric, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.Biometric, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.Biometric = $.grep(indexDBResult.Biometric, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.Biometric, result.Biometric);



                            }
                        }
                    }

                    if (result.Goals && result.Goals.length > 0) {
                        if (!indexDBResult.Goals) {
                            isDBNeedUpdated = true;
                            indexDBResult.Goals = result.Goals;
                        }
                        else {

                           // indexDBResult.Goals = result.Goals;
                           // Array.prototype.push.apply(indexDBResult.Goals, result.Goals);
                            let alreadyExisitngServices = $.grep(result.Goals, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.Goals, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.Goals = $.grep(indexDBResult.Goals, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.Goals, result.Goals);



                            }
                        }
                    }

                    if (result.Responses && result.Responses.length > 0) {
                        if (!indexDBResult.Responses) {
                            isDBNeedUpdated = true;
                            indexDBResult.Responses = result.Responses;
                        }
                        else {
                            // indexDBResult.CenterNotes = result.CenterNotes;
                            //Array.prototype.push.apply(indexDBResult.CenterNotes, result.CenterNotes);
                            let alreadyExisitngServices = $.grep(result.Responses, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.Responses, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.Responses = $.grep(indexDBResult.Responses, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.Responses, result.Responses);



                            }
                        }
                    }

                    if (result.CenterNotes && result.CenterNotes.length > 0) {
                        if (!indexDBResult.CenterNotes) {
                            isDBNeedUpdated = true;
                            indexDBResult.CenterNotes = result.CenterNotes;
                        }
                        else {
                           // indexDBResult.CenterNotes = result.CenterNotes;
                            //Array.prototype.push.apply(indexDBResult.CenterNotes, result.CenterNotes);
                            let alreadyExisitngServices = $.grep(result.CenterNotes, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.CenterNotes, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.CenterNotes = $.grep(indexDBResult.CenterNotes, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.CenterNotes, result.CenterNotes);



                            }
                        }
                    }
                    if (result.Fee && result.Fee.length > 0) {
                        if (!indexDBResult.Fee) {
                            isDBNeedUpdated = true;
                            indexDBResult.Fee = result.Fee;
                        }
                        else {
                            //Array.prototype.push.apply(indexDBResult.Fee, result.Fee);
                            let alreadyExisitngServices = $.grep(result.Fee, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.Fee, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.Fee = $.grep(indexDBResult.Fee, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.Fee, result.Fee);



                            }
                        }
                    }
                    if (result.Feedback && result.Feedback.length > 0) {
                        if (!indexDBResult.Feedback) {
                            isDBNeedUpdated = true;
                            indexDBResult.Feedback = result.Feedback;
                        }
                        else {
                            //Array.prototype.push.apply(indexDBResult.Fee, result.Fee);
                            let alreadyExisitngServices = $.grep(result.Feedback, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.Feedback, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.Feedback = $.grep(indexDBResult.Feedback, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.Feedback, result.Feedback);



                            }
                        }
                    }

                    if (result.Leads && result.Leads.length > 0) {
                        if (!indexDBResult.Leads) {
                            isDBNeedUpdated = true;
                            indexDBResult.Leads = result.Leads;
                        }
                        else {

                            // indexDBResult.Goals = result.Goals;
                            // Array.prototype.push.apply(indexDBResult.Goals, result.Goals);
                            let alreadyExisitngServices = $.grep(result.Leads, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.Leads, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.Leads = $.grep(indexDBResult.Leads, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.Leads, result.Leads);



                            }
                        }
                    }


                    if (result.FeeReceipt && result.FeeReceipt.length > 0) {
                        if (!indexDBResult.FeeReceipt) {
                            isDBNeedUpdated = true;
                            indexDBResult.FeeReceipt = result.FeeReceipt;
                        }
                        else {
                            let alreadyExisitngServices = $.grep(result.FeeReceipt, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.FeeReceipt, function (val, i) {
                                return val.Id + ""
                            });
                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.FeeReceipt = $.grep(indexDBResult.FeeReceipt, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.FeeReceipt, result.FeeReceipt);



                            }
                        }
                    }
                    if (result.People && result.People.length > 0) {
                        if (!indexDBResult.People) {
                            isDBNeedUpdated = true;
                            indexDBResult.People = result.People;
                        }
                        else {
                            //Array.prototype.push.apply(indexDBResult.People, result.People);
                            let alreadyExisitngServices = $.grep(result.People, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.People, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.People = $.grep(indexDBResult.People, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.People, result.People);



                            }
                        }
                    }

                    if (result.FacilityPeople && result.FacilityPeople.length > 0) {
                        if (!indexDBResult.FacilityPeople) {
                            isDBNeedUpdated = true;
                            indexDBResult.FacilityPeople = result.FacilityPeople;
                        }
                        else {
                            //Array.prototype.push.apply(indexDBResult.People, result.People);
                            let alreadyExisitngServices = $.grep(result.FacilityPeople, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.FacilityPeople, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.FacilityPeople = $.grep(indexDBResult.FacilityPeople, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.FacilityPeople, result.FacilityPeople);



                            }
                        }
                    }


                    if (result.PeopleNotes && result.PeopleNotes.length > 0) {
                        if (!indexDBResult.PeopleNotes) {
                            isDBNeedUpdated = true;
                            indexDBResult.PeopleNotes = result.PeopleNotes;
                        }
                        else {


                            let alreadyExisitngServices = $.grep(result.PeopleNotes, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.PeopleNotes, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.PeopleNotes = $.grep(indexDBResult.PeopleNotes, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.PeopleNotes, result.PeopleNotes);



                            }
                            //Array.prototype.push.apply(indexDBResult.PeopleNotes, result.PeopleNotes);
                        }
                    }

                    

                    if (result.ServiceRequests && result.ServiceRequests.length > 0) {
                        if (!indexDBResult.ServiceRequests) {
                            isDBNeedUpdated = true;
                            indexDBResult.ServiceRequests = result.ServiceRequests;
                        }
                        else {

                            let alreadyExisitngServices = $.grep(result.ServiceRequests, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.ServiceRequests, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.ServiceRequests = $.grep(indexDBResult.ServiceRequests, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.ServiceRequests, result.ServiceRequests);



                            }

                           // Array.prototype.push.apply(indexDBResult.ServiceRequests, result.ServiceRequests);
                        }
                    }


                    if (result.ServiceRequestsAnalytics && result.ServiceRequestsAnalytics.length > 0) {
                        if (!indexDBResult.ServiceRequestsAnalytics) {
                            isDBNeedUpdated = true;
                            indexDBResult.ServiceRequestsAnalytics = result.ServiceRequestsAnalytics;
                        }
                        else {

                            let alreadyExisitngServices = $.grep(result.ServiceRequestsAnalytics, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.ServiceRequestsAnalytics, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.ServiceRequestsAnalytics = $.grep(indexDBResult.ServiceRequestsAnalytics, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.ServiceRequestsAnalytics, result.ServiceRequestsAnalytics);



                            }

                            // Array.prototype.push.apply(indexDBResult.ServiceRequests, result.ServiceRequests);
                        }
                    }

                    if (result.MirracleTypes && result.MirracleTypes.length > 0) {
                        if (!indexDBResult.MirracleTypes) {
                            isDBNeedUpdated = true;
                            indexDBResult.MirracleTypes = result.MirracleTypes;
                        }
                        else {

                            isDBNeedUpdated = true;
                            indexDBResult.MirracleTypes = result.MirracleTypes;

                        }
                    }

                    if (result.Guru && result.Guru.length > 0) {
                        if (!indexDBResult.Guru) {
                            isDBNeedUpdated = true;
                            indexDBResult.Guru = result.Guru;
                        }
                        else {

                            let alreadyExisitngServices = $.grep(result.Guru, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.Guru, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.Guru = $.grep(indexDBResult.Guru, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.Guru, result.Guru);



                            }

                        }
                    }



                    if (result.Therapies && result.Therapies.length > 0) {
                        if (!indexDBResult.Therapies) {
                            isDBNeedUpdated = true;
                            indexDBResult.Therapies = result.Therapies;
                        }
                        else {

                            let alreadyExisitngServices = $.grep(result.Therapies, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.Therapies, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.Therapies = $.grep(indexDBResult.Therapies, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.Therapies, result.Therapies);



                            }

                        }
                    }

                    if (result.Offers && result.Offers.length > 0) {
                        if (!indexDBResult.Offers) {
                            isDBNeedUpdated = true;
                            indexDBResult.Offers = result.Offers;
                        }
                        else {

                            let alreadyExisitngServices = $.grep(result.Offers, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.Offers, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.Offers = $.grep(indexDBResult.Offers, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.Offers, result.Offers);



                            }

                        }
                    }


                    if (result.FacilityTherapies && result.FacilityTherapies.length > 0) {
                        if (!indexDBResult.FacilityTherapies) {
                            isDBNeedUpdated = true;
                            indexDBResult.FacilityTherapies = result.FacilityTherapies;
                        }
                        else {

                            let alreadyExisitngServices = $.grep(result.FacilityTherapies, function (e) { return e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(alreadyExisitngServices[i].Id);
                                }
                            }
                            var eIds = $.map(result.FacilityTherapies, function (val, i) {
                                return val.Id + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.FacilityTherapies = $.grep(indexDBResult.FacilityTherapies, function (e) { return !(eIds.indexOf(e.Id + "") > -1 || alreadyExistServiceIds.indexOf(e.Id + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.FacilityTherapies, result.FacilityTherapies);



                            }

                        }
                    }
                    
                    console.log("ACTIVITIES GROUP BY");
                    //console.log(groupBy(indexDBResult.Data, "AT"));
                    // #endregion ============ User Data =============

                    if (result.INDIVIDUALAGGREGATES && result.INDIVIDUALAGGREGATES.length > 0) {
                        if (!indexDBResult.INDIVIDUALAGGREGATES) {
                            isDBNeedUpdated = true;
                            indexDBResult.INDIVIDUALAGGREGATES = result.INDIVIDUALAGGREGATES;
                        }
                        else {
                            var ids = $.map(result.INDIVIDUALAGGREGATES, function (e) { return e._key });
                            indexDBResult.INDIVIDUALAGGREGATES = $.grep(indexDBResult.INDIVIDUALAGGREGATES, function (r) { return ids.indexOf(r._key) == -1 });
                            indexDBResult.INDIVIDUALAGGREGATES.push(...result.INDIVIDUALAGGREGATES);
                        }
                    }

                    if (result.LastSyncDataTime) {
                        indexDBResult.LastSyncDataTime = result.LastSyncDataTime;
                        localStorage.setItem("LastPSSyncDataTime", result.LastSyncDataTime);
                    }

                    if (isDBNeedUpdated) {
                        //brotli
                        cache.put("/PSUserData", new Response(JSON.stringify(indexDBResult)));
                    }
                    try {
                        setTimeout(function () {


                            //var user = indexDBResult.CFUser;

                            //// indexDBResult.Data.filter(m => m.)

                            //var tags = [];
                            //tags.push(user.RDT);
                            //tags.push("DOJ:" + user.CDT);
                            //try {
                            //    var servicesData = $.grep(indexDBResult.Data, function (e) { return e.AT == "USERSERVICE" && e.RS != "DELETEACTIVITY" && e.S != "INACTIVE" && e.S != "InActive" && e.RDUId[0] == user.OGID });
                            //    if (null != servicesData && servicesData.length > 0) {
                            //        tags.push(...servicesData.map(function (m) { return m.RDT + " @ " + m.F1 + " " + m.F2 }));
                            //    }

                            //    var servicesDataBoss = $.grep(indexDBResult.Data, function (e) { return e.AT == "USERSERVICE" && e.RS != "DELETEACTIVITY" && e.S != "INACTIVE" && e.S != "InActive" && e.TOId == user.OGID });
                            //    if (null != servicesDataBoss && servicesDataBoss.length > 0) {
                            //        tags.push(...servicesDataBoss.map(function (m) { return m.RDT + " @ " + m.F1 + " " + m.FDT }));
                            //    }

                            //} catch (e) {

                            //}
                            //tidioChatApi.addVisitorTags(tags);





                        }, 6 * 1000);
                    } catch (e) {

                    }

                    if (callback) {
                        callback(indexDBResult);
                    }
                } catch (e) {
                    console.log("Error IN UpdateUserDataInCacheDB While storing the wall data in cache api : " + e);
                }
            }).catch(error => {
                console.log("Error In UpdateUserDataInCacheDB While storing the wall data in cache api : " + error);
            });
        });

    } catch (e) {
        SaveCleintWaufli(e,"UpdateUserDataInCacheDB Failed"," UpdateUserDataInCacheDB ");
       
        SaveExceptionToSentry(e, "Client UpdateUserDataInCacheDB", "", JSON.stringify(e));
        console.log(e);
    }
}

function UpdateDangDataInCacheDB(result, callback) {
    console.log("Update Function Called");
    try {
        caches.open(apiCacheName).then(cache => {
            cache.match("/PSUserData").then(async response => {
                try {
                    var indexDBResult = response ? await response.json() : null;
                    var isDBNeedUpdated = false;
                    if (indexDBResult == null || indexDBResult == "" || typeof (indexDBResult) == "undefined")
                        indexDBResult = {};

                   


                    // #region ============ User Data =============
                    if (result.Data && result.Data.length > 0) {


                        if (!indexDBResult.Dang) {
                            isDBNeedUpdated = true;
                            indexDBResult.Dang = result.Data;
                        }
                        else {
                            let alreadyExisitngServices = $.grep(result.Data, function (e) { return e.AT == "USERSERVICE" && e.Id });
                            let alreadyExistServiceIds = [];
                            if (alreadyExisitngServices) {
                                for (var i = 0; i < alreadyExisitngServices.length; i++) {
                                    alreadyExistServiceIds.push(...alreadyExisitngServices[i].Id.split("{#}"));
                                }
                            }
                            var eIds = $.map(result.Data, function (val, i) {
                                return val.UId + ""
                            });

                            if (eIds != null && eIds.length > 0) {
                                isDBNeedUpdated = true;
                                indexDBResult.Dang = $.grep(indexDBResult.Dang, function (e) { return !(eIds.indexOf(e.UId + "") > -1 || alreadyExistServiceIds.indexOf(e.UId + "") > -1) });
                                Array.prototype.push.apply(indexDBResult.Dang, result.Data);



                            }
                        }

                        indexDBResult.Dang = $.grep(indexDBResult.Dang, function (e) { return e.RS != "DELETEACTIVITY" });
                    }
                    console.log("ACTIVITIES GROUP BY");
                    console.log(groupBy(indexDBResult.Dang, "AT"));
                    // #endregion ============ User Data =============



                    if (isDBNeedUpdated) {
                        cache.put("/PSUserData", new Response(JSON.stringify(indexDBResult)));
                    }

                    if (callback) {
                        callback(indexDBResult);
                    }
                } catch (e) {
                    console.log("Error IN UpdateDangDataInCacheDB While storing the wall data in cache api : " + e);
                }
            }).catch(error => {
                console.log("Error In UpdateDangDataInCacheDB While storing the wall data in cache api : " + error);
            });
        });

    } catch (e) {
        SaveExceptionToSentry(e, "Client UpdateDangDataInCacheDB", "", JSON.stringify(e));
        console.log(e);
    }
}


function getSocketErrorResponse(msg) {
    try {
        var errorObj = {};
        errorObj.Status = "ERROR";
        errorObj.ErrorMsg = msg;
        return errorObj;
    } catch (err) {
        console.log("Error in getSocketErrorResponse : " + err);
    }
}

function getMinutesBetweenDates(startDate, endDate) {
    var diff = endDate.getTime() - startDate.getTime();
    return (diff / 60000);
}

function convertDateToUTC(date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
}